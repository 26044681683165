import { capitalizeFirstChar } from "./utils";

class Record {

    transformRecordNames = (data) => {
        let names = data.split(' ');
        let capitalizedNames = [];
        names.forEach(element => {
            capitalizedNames.push(capitalizeFirstChar(element));
        });
        let lastname ="";
        let othernames="";
        let firstname = capitalizedNames[0];
        if (capitalizedNames.length === 2)
            othernames = capitalizedNames[1]
        else if (capitalizedNames.length === 3)
            othernames = capitalizedNames[1] + ' ' + capitalizedNames[2];
        else if (capitalizedNames.length > 3) {
            othernames = capitalizedNames.slice(1, -1).join(' ');
            lastname = capitalizedNames[capitalizedNames.length - 1];
        }
        return {firstname: firstname, lastname: lastname, othernames: othernames}
    }

}

export const record = new Record();