import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_ADVANCED_SEARCH } from "../../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";

import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import {
    getOrderBy,
    getOrderDirection,
    getDefaultFilter,
    getErrorList,
    transformAdvancedSearchData,
    advancedSearchfields,
    getRowActions,
    getContext,
    filterDataLogic,
    getDataEndpoint,
    countDataEndpoint
} from "../../../../main/js/lists/registreAffectation/RegistreAffectationListCommon.jsx";

import {
    getMtlbStatus,
    MtlbStatus,
    MTLB_STATUS_NOT_ASSIGNED,
    MTLB_STATUS_ASSIGNED
} from "../../../../auto/js/metadata/MtlbStatus";

import { displayReadNotAssignedRegistreAffectationForm } from "../../forms/registreAffectation/NotAssignedRegistreAffectationForm";
import { displayReadAssignedRegistreAffectationForm } from "../../forms/registreAffectation/AssignedRegistreAffectationForm";




const registreAffectationListFields = [
    {
        title: "ID",
        field: "id",
    },
    {
        title: "Substitut ou Procureur",
        field: "substitut",
    },
    {
        title: "folderId",
        field: "folderId",
    },
    {
        title: "plaignants",
        field: "plaignants",
    },
    {
        title: "misCause",
        field: "misCause",
    },
    {
        title: "victimes",
        field: "victimes",
    },
    {
        title: "caseStatus",
        field: "caseStatus",
    },
    {
        title: "Actions",
        field: "actions",
        render: (rowData) => getRowActions(rowData)
    }
]

const RegistreAffectationListPage = ({uuid, gui, defaultFilter, searchData}) => {
    const [notAssigned, setNotAssigned] = useState(false);
    const [assigned, setAssigned] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [selectAllStatus, setSelectAllStatus] = useState(false);
    const [selectAllError, setSelectAllError] = useState(false);
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);
    let RegistreAffectationTable = createTableComponent(registreAffectationListFields);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("notAssigned"):
                setNotAssigned(value.target.checked);
                break;
            case ("assigned"):
                setAssigned(value.target.checked);
                break;
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
                setNotAssigned(value.target.checked);
                setAssigned(value.target.checked);
				break;
        }
    }
    const buildData = async (query) => {
        let filter = query;
        let data;
        filter["registre-affectation-document-dto"] = (getDefaultFilter)?getDefaultFilter():defaultFilter?defaultFilter:{};
        let mtlbStatusList = [];
        if (notAssigned) {
            mtlbStatusList.push(MTLB_STATUS_NOT_ASSIGNED);
        }
        if (assigned) {
            mtlbStatusList.push(MTLB_STATUS_ASSIGNED);
        }
        if ( !notAssigned  &&  !assigned )
        mtlbStatusList = [ MTLB_STATUS_NOT_ASSIGNED,  MTLB_STATUS_ASSIGNED];
        filter["registre-affectation-document-dto"].mtlbStatusList = mtlbStatusList;
        filter['and'] = true;
        filter["order-by"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter["order-direction"] = (getOrderDirection)?getOrderDirection():null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["registre-affectation"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["registre-affectation-document-dto"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            data = filterData(response, gui);
            return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
    }

    const onErrorFacetChange = (key, value) => {
        let list = _.clone(errorList);
        if (key === "selectAllError") {
            if (value.target.checked)
                list = selectAllErrors();
            else
                list = [];
            setErrorList(list);
            setSelectAllError(value.target.checked)
        }
        if (value.target.checked && !list.includes(key)) {
            list.push(key);
            setErrorList(list);
        }
        else if (!value.target.checked && list.includes(key)) {
            let index = list.indexOf(key);
            list.splice(index, 1);
            setErrorList(list);
        }
    }

    const getErrorCodeFacets = () => {
      let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
        getErrorList().forEach(error => {
            list.push({key: error, value: errorList.includes(error), label: t(error)});

        });
        return list;
    }

    const onAdvancedSearchSubmit = (data) => {
        let dto = transformAdvancedSearchData(data);
        setAdvancedSearchData(dto);
    }

    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>
            <Facets title={t`Application Status`} facets={[{key: "selectAllStatus", value: selectAllStatus, separator: true}, { key: "notAssigned", value: notAssigned }, { key: "assigned", value: assigned }]} facetsComponents={(key, value) => buildFacets(key, value, t(key), onFacetChange)} />
            {(getErrorList) && <Facets title={t`Errors`} facets={getErrorCodeFacets()} facetsComponents={(key, value) => buildFacets(key, value, t(key), onErrorFacetChange)} />}
            <RegistreAffectationTable key={uuid} loadData={async (query) => buildData(query)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
    	</div>
    )
}

export const displayRegistreAffectationList = (gui, defaultFilter, advancedSearchData) => {
	const readRegistreAffectationList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <RegistreAffectationListPage gui={gui} key={uuid} defaultFilter={defaultFilter} searchData={advancedSearchData}/>, getContext: (getContext) && getContext
		};
	}
	gui.goTo(readRegistreAffectationList)
}

export const displayRegistreAffectationAdvancedSearch = (gui) => {
    let advancedSearchFilters = {fields: advancedSearchfields, name: t`RegistreAffectation`, onSubmit: (filter) => onAdvancedSearchSubmit(filter, gui)};
	OPEN_ADVANCED_SEARCH.publish(advancedSearchFilters);
}

const selectAllErrors = () => {
    let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

const onAdvancedSearchSubmit = (filter, gui) => {
    displayRegistreAffectationList(gui, transformAdvancedSearchData(filter));
}

export const getData = async (filter) => {
	 if (getDataEndpoint)
        return await getDataEndpoint(filter);
    else {
		let searchEndpoint = (filter.query) ? "registre-affectation/advanced-search":"registre-affectation/search-document/"
    	return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
	}
}

export const countData = async (filter) => {
	if (countDataEndpoint)
        return await countDataEndpoint(filter);
    else {
		let countEndpoint = (filter.query) ? "registre-affectation/count/advanced-search":"registre-affectation/count-document/"
    	return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
	}
}

export const filterData = (defaultRows, gui) => {
	let rows = (filterDataLogic)?filterDataLogic(defaultRows):defaultRows;
    const newRows = [];
    for (let i in rows) {
        let row = rows[i];
        row.gui = gui;
        newRows.push(row);
    };
    return newRows;
}