import { rest } from "../../../../auto/js/services";
import { getServiceUri } from "../../../../auto/js/metadata";


export const customAudienceForm2Dto = (formData, dto) => {
    if (dto.id)
        rest.request(getServiceUri() + 'case-data/add-audience', 'POST', dto);
}



export const getAvocatsOptions = async () => {
    return rest.request(getServiceUri() + 'barreau/get-avocats', 'GET').then(response => {
        const result = response.reduce((acc, item) => {
            acc[item.id] = `${item.name}`;
            return acc;
          }, {});
          return result
    })
}

export const customAudienceDto2form = (dto, form) => {
    /* rest.read('forms/citation-cour-d-appel', form["id"], getDjServicesUrl()).then(async citationCourDAppelData => {
        rest.read('forms/convocation', form["id"], getDjServicesUrl()).then(async convocationData => {
            rest.read('forms/extrait-plumitif', form["id"], getDjServicesUrl()).then(async plumitifData => {
                setContextualOptions({
                    "dossier": {
                        submenu: {
                            "actions": {
                                label: "Actions",
                                options: {
                                    "print-citation-cour-d-appel": {
                                        label: "Imprimer citation cour d'appel",
                                        do: printTemplate(citationCourDAppelData)
                                    },
                                    "print-convocation": {
                                        label: "Imprimer convocation",
                                        do: printTemplate(convocationData)
                                    },
                                    "print-extrait-plumitif": {
                                        label: "Imprimer extrait de plumitif",
                                        do: printTemplate(plumitifData)
                                    },
                                    "nouveau-ma": {
                                        label: "Nouveau mandat d'amener",
                                        do: (gui) => {
                                            let mdFormData = {audienceDate: form.date, audienceHour: form.time}
                                            mdFormData.president = whoami().lastname + ' ' + whoami().firstname;
                                            let personnes = '';
                                            if (form.misCauses) {
                                                Object.values(form.misCauses).forEach(element => {
                                                    personnes = personnes + ' ' + element;
                                                });
                                                mdFormData.personne = personnes;
                                            } 
                                            displayNewMandatAmenerForm(gui, mdFormData)
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
            })
        })
    }) */
    /* if (dto.avocats == null || dto.avocats === "")
        dto.avocats = []
    else if(dto.avocats.length) {
        let values = [];
        let components = dto.avocats.split(",");
        components.forEach(element => {
            values.push(parseInt(element))
        });
        dto.avocats = values;
    } */
   
}