import { printTemplate } from "../../utils";
import { rest } from "../../../../auto/js/services";
import { setContextualOptions } from "../../../../auto/js/widgets";
export const customJugementForm2Dto = (formData, dto) => {

}

export const customJugementDto2Form = (dto, form) => {
    
}

export const folderIdDisplay = () => {
    return false;
}