import React from "react";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";
import { CountryAutoCompleteInput } from "../../widgets/CountryAutoCompleteInput";
import { rest, t } from "../../../../auto/js/services";
import TemplateComponent from "react-mustache-template-component";
import { geoDataMetadataLoader } from "../../../../auto/js/metadata/GeoDataMetadataLoader";
import { PRINT_EVENT } from "../../../../auto/js/events/Gui";
import { update } from "../../../../auto/js/forms/mandatDepot/NewMandatDepotForm";

export const customMandatDepotForm2Dto = (formData, dto) => {

}

export const customMandatDepotDto2Form = (dto, form) => {

}

export const birthPlaceComponentHook = (name, disabled) => {
    return <GeoDataComponent name={name} disabled={disabled} linear={true} default={"48"}/>
}

export const citizenshipComponentHook = (name, disabled) => {
    return <CountryAutoCompleteInput name={name} disabled={disabled} default={"48"}/>
}

export const addressComponentHook = (name, disabled) => {
    return <GeoDataComponent name={name} disabled={disabled} linear={true} default={"48"}/>
}

export const printMandatDepot = async (data) => {
    return update(data).then(async () => {
        return rest.read('forms/mandat-de-depot', data.id).then(async md => {
            printTemplate(md)
        })
    })
}

export const print = (id) => {
    rest.read('forms/mandat-de-depot', id).then(async soitTransmis => {
        printTemplate(soitTransmis)
    })
}

const printTemplate = (data) => {
	PRINT_EVENT.publish({
		content: <TemplateComponent template={data.template} data={dto2form(data.templateData)} />,
		copyParentStryle: false
	})
}

const dto2form = (dto) => {
	let form = dto; 
	if (dto.birthPlace != null)
		form.birthPlace = formatLocation(dto.birthPlace);
	if (dto.citizenship != null)
		form.citizenship = formatLocation(dto.citizenship);
	if (dto.address != null)
		form.address = formatLocation(dto.address);
	if (dto.birthdate != null)
		form.birthdate = formatDate(dto.birthdate);
	if (dto.flagrantDelits != null)
		form.flagrantDelits = formatDate(dto.flagrantDelits);
	if (dto.citationDirect != null)
		form.citationDirect = formatDate(dto.citationDirect);
	var currentdate = new Date();
	form.nowDate = currentdate.getDate() + "/"
	+ (currentdate.getMonth() + 1) + "/"
	+ currentdate.getFullYear();
	return form;
}

const formatDate = (date) => {
	let d = (date[2] >= 10)?date[2]:"0"+date[2];
	let m = (date[1] >= 10)?date[1]:"0"+date[1];
	return d + "/" + m + "/" + date[0];
}

const formatLocation = (location) => {
	let birthPlace = [];
	if (location != null) {
		let birthPlaceComponents = location.split(".");
		let place = location;
		birthPlace.push(
			(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name):t("INVALID DATA ")
		)
		for (let i = 0; i < birthPlaceComponents.length - 1 ; i ++) {
			let parentAreaId = place.substring(0, place.lastIndexOf("."));
			place = parentAreaId;
			birthPlace.push(
				(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name):t("INVALID DATA ")
			)                
		}
	}
	return birthPlace;
}