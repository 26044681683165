import React from "react";

import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { DELETE_ATTACHMENT_EVENT, PRINT_EVENT } from "../../../../auto/js/events/Gui";
import { Section } from "../../../../auto/js/widgets/Section";
import { createRecord, getRecordsOptions, readRecord } from "../../utils";
import TemplateComponent from "react-mustache-template-component";
import { whoami } from "../../../../auto/js/users/UserInfo";

import AutocompleteDynamicListSelectorField from "../../widgets/AutocompleteDynamicListSelectorField";

const incidentCategory = {
	1: "CONSUMER_RIGHTS",
	2: "ENVIRONMENTAL_CONCERNS",
	3: "PUBLIC_SAFETY",
	4: "GOVERNMENTAL_CONCERN"
}

const unit = {
	1: "POL",
	2: "GEN",
	3: "GAC",
}

const complainantContactPreferences = {
	1: "EMAIL",
	2: "PHONE"
}

export const plaignantsComponentHook = (name, disabled) => {
	return <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Plaignants`} />
}

export const AutresComponentHook = (name, disabled) => {
	return <Section name={name} />
}

export const victimesComponentHook = (name, disabled) => {
	return <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Victimes`} />
}

export const misCausesComponentHook = (name, disabled) => {
	return <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Mis en Causes`} />
}



export const customRegistrePoursuiteForm2Dto = (formData, dto) => {
	if (formData.victimes || formData.misCauses || formData.plaignants)
		rest.purge('case-data/parties', formData.folderId).then(() => {
			if (formData.victimes) {
				Object.keys(formData.victimes).forEach(element => {
					let names = formData.victimes[element].split(' ');
					let lastname ="";
					let firstname = names[0];
					if (names.length > 1)
						lastname = names[names.length - 1];
					let data = {folderId: formData.folderId, recordsId: element, firstname: firstname, lastname: lastname}
					rest.create('victimes', data);
				});			
			}
			if (formData.misCauses) {
				Object.keys(formData.misCauses).forEach(element => {
					let names = formData.misCauses[element].split(' ');
					let lastname ="";
					let firstname = names[0];
					if (names.length > 1)
						lastname = names[names.length - 1];
					let data = {folderId: formData.folderId, recordsId: element, firstname: firstname, lastname: lastname}
					rest.create('mis-cause', data);
				});
			}
			if (formData.plaignants) {
				Object.keys(formData.plaignants).forEach(element => {
					let names = formData.plaignants[element].split(' ');
					let lastname ="";
					let firstname = names[0];
					if (names.length > 1)
						lastname = names[names.length - 1];
					let data = {folderId: formData.folderId, recordId: element, firstname: firstname, lastname: lastname}
					rest.create('plaignants', data);
				});
			}
			
		})	
}

export const customRegistrePoursuiteDto2Form = (dto, form) => {
    
}

export const buildRegistrePoursuiteFormEmptyObject  = () => {
	return buildEmptyObject(registrePoursuiteFields(whoami()));
}

export const newRegistrePoursuiteFormForm2Dto = (form, dto) => {
	
}