import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import { CAN_I_SWITCH } from "../../../../auto/js/events/Gui";
import { pojoMetadata, getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification, swapObject } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import { customMandatAmenerForm2Dto, customMandatAmenerDto2Form, getContext } from "../../../../main/js/forms/mandatAmener/MandatAmenerFormCommon";
import * as Yup from 'yup';
import {
    printMandatAmener
} from "../../../../main/js/forms/mandatAmener/MandatAmenerFormCommon";


export const mandatAmenerFields = [
    {
        name: "id",
        label: "id",
        type: "number",
        x: "1",
        y: "1",
        layout: "col-md-6",
    },
    {
        name: "rp",
        label: "rp",
        type: "number",
        x: "2",
        y: "1",
        layout: "col-md-6",
    },
    {
        name: "pv",
        label: "pv",
        type: "text",
        x: "1",
        y: "2",
        layout: "col-md-6",
    },
    {
        name: "president",
        label: "president",
        type: "text",
        x: "1",
        y: "3",
        layout: "col-md-6",
    },
    {
        name: "personne",
        label: "personne",
        type: "text",
        x: "1",
        y: "4",
        layout: "col-md-6",
    },
    {
        name: "audienceDate",
        label: "audienceDate",
        type: "date",
        x: "1",
        y: "5",
        layout: "col-md-6",
    },
    {
        name: "audienceHour",
        label: "audienceHour",
        type: "time",
        x: "2",
        y: "5",
        layout: "col-md-6",
    }
]


export const form2dto = (formData, dto) => {
    if (formData.id != null) {
        dto.id = parseInt(formData.id)
    }
    if (formData.rp != null) {
        dto.rp = parseInt(formData.rp)
    }
    if (formData.audienceDate != null && typeof(formData.audienceDate) != 'string') {
        let audienceDateAsDate = new Date(formData.audienceDate);
        dto.audienceDate = audienceDateAsDate.getFullYear() + "-" + ('0' + (audienceDateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + audienceDateAsDate.getDate()).slice(-2);
    }
    if (formData.audienceHour != null && typeof(formData.audienceHour) != 'string') {
        const audienceHourDate = new Date(formData.audienceHour)
        let audienceHourHour = audienceHourDate.getHours() < 10 ? '0' + audienceHourDate.getHours() : audienceHourDate.getHours();
        let audienceHourMinutes = audienceHourDate.getMinutes() < 10 ? '0' + audienceHourDate.getMinutes() : audienceHourDate.getMinutes();
        dto.audienceHour = audienceHourHour + ":" + audienceHourMinutes;
    }
    customMandatAmenerForm2Dto(formData, dto);
}

export const dto2form = (dto, token) => {
    let form =  _.clone(dto);
    if (dto.audienceDate !== null) {
        form.audienceDate = new Date().setFullYear(dto.audienceDate[0], dto.audienceDate[1], dto.audienceDate[2]);
    }
    if (dto.audienceHour !== null) {
        form.audienceHour = new Date().setHours(dto.audienceHour[0], dto.audienceHour[1]);
    }
    customMandatAmenerDto2Form(dto, form, token);
    return form;
}

const getButtons = (data, onFinish) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    
                        <button style={{ minWidth: '5rem' }} className={'reject-button'} type="button" onClick={(onFinish) ? () => printMandatAmener(data).then(() => onFinish()) : () => printMandatAmener(data)}>
                            {t`Imprimer`}
                        </button>
                    
                </div>
            </div>
        </>
    )
}

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
	    let MandatAmenerForm = createFormComponent(mandatAmenerFields);
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<MandatAmenerForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={(data) => getButtons(data, this.props.onFinish)} />
		</>
		)
	}
}

export const displayNewMandatAmenerSidePanel = (gui, onFinish, formData) => {
	const readNewMandatAmenerSidePanel = (onFinish) => (id) => {
        let uuid = uuidv4();
           gui.openSidePanel(<FormComponent key={uuid} onFinish={onFinish} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>)
    }
    let data;
    if (!formData) {
        data = buildEmptyObject(mandatAmenerFields);
        data.draft = true;
        save(data).then((response) => {
            readNewMandatAmenerSidePanel(onFinish)(response)
        });
    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            readNewMandatAmenerSidePanel(onFinish)(response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    readNewMandatAmenerSidePanel(onFinish)(response)
                });
            }
        } else {
            save(data).then((response) => {
                readNewMandatAmenerSidePanel(onFinish)(response)
            });
        }
    }
}

export const displayNewMandatAmenerForm = (gui, onFinish, formData) => {
	const readNewMandatAmenerForm = (onFinish) => (id) => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then((response) => onFinish(response)):update} id={id}/>, getContext: (getContext) && getContext
        };

    }
    let data;
    if (!formData) {
        data = buildEmptyObject(mandatAmenerFields);
        data.draft = true;
        save(data).then((response) => {
            gui.goTo(readNewMandatAmenerForm(onFinish), response)
        });

    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            gui.goTo(readNewMandatAmenerForm(onFinish), response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    gui.goTo(readNewMandatAmenerForm(onFinish), response)
                });
            }
        } else {
            save(data).then((response) => {
                gui.goTo(readNewMandatAmenerForm(onFinish), response)
            });
        }
    }
}

export const displayReadMandatAmenerForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
	};
}
export const displayReadMandatAmenerSidePanel = (gui, id, onFinish) => {
	const readReadMandatAmenerSidePanel = (onFinish) => (id) => {
		let uuid = uuidv4();
		gui.openSidePanel(<FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>);
	}
	readReadMandatAmenerSidePanel(onFinish)(id);
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
            case ("image"):
                empty[field.name] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true};
                break;
		}
	}
	return empty;
}

export const loadFormData = async (id) => {
    let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	return await rest.read('mandat-amener', id).then(response => {
		let form = dto2form(response, token);
		return form;
	})
}

export const save = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['mandat-amener'].form2dto(data);
	form2dto(formData, dto);
	try {
        return rest.request(getServiceUri() + 'mandat-amener/new-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
				return response;
		});
    } catch (err) {
        alert(err);
    }
}

export const update = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['mandat-amener'].form2dto(data);
	form2dto(formData, dto)
	try {
        return rest.request(getServiceUri() + 'mandat-amener/edit-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated mandatAmener`, "success");
		});
    } catch (err) {
        alert(err);
    }
}