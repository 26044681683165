import React from "react";
import { IconButton } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { t } from "../../../auto/js/services";
import { CLOSE_SIDE_PANEL, OPEN_SIDE_PANEL } from "../../../auto/js/events/Gui";
import { createFormComponent } from "../../../auto/js/widgets";
import { whoami } from "../../../auto/js/users/UserInfo";
import { dto2form, registreOppositionFields, update } from "../../../auto/js/forms/registreOpposition/NewRegistreOppositionForm";
import { servicesRest } from "../services/ServicesRestClient";

export const RegistreOppositionSummary = ({...props}) => {
    return(
      <RegistreOpposition obj={props.obj} columns={['rpId', 'legacyId', 'partie']} footerElements= {['created', 'lastModified']} title={"Entrée dans le registre d'opposition"} gui={props.gui}/>
      )
}

class RegistreOpposition extends React.Component {
    constructor(props) {
      super(props);
      this.formRef = React.createRef()
      this.state = {
        data: props.obj.data,
        loading: true,
        loadingMessage: ''
      }
      this.updateData = this.updateData.bind(this);
      props.obj.observable.subscribe(this.updateData)
    }

    transformData = async (data) => {
        let transformed = _.clone(data);
        return transformed;
    }

    buildBody = () => {
        let items = [];
        let colClass = "col-" + Math.round(12/this.props.columns.length)
        this.props.columns.forEach(element => {
            items.push(
                <div class={colClass}>
                    <div class="results">
                        <h4>{t(element)}</h4>
                        {this.transformData(this.state.data)[element]}
                    </div>
                </div>
            )
        });
        return items;
    }

    updateData = (data) => {
        this.setState({data: data})
    }

    buildFooter = () => {
        let items = [];
        this.props.footerElements.forEach(element => {
            <p style={{fontStyle: "italic", color: "#898c8e"}}>{t(element)}: {this.state.data[element]}</p>
        });
        return items;
    }

    loadFormData = async () => {
        let form = dto2form(this.state.data);
        if (this.state.data.partie == null || this.state.data.partie === "")
            this.state.data.partie = {}
        else {
            let values = {};
            let components = this.state.data.partie.split(",");
            // Use Promise.all to wait for all rest.read calls
            await Promise.all(
                components.map(async (element) => {
                    const r = await servicesRest.read('records', element);
                    values[parseInt(element)] = r.firstname + ' ' + r.othernames + ' ' + r.lastname;
                })
            );
            form.partie = values;
        }
        return form;
    }

    form2persist = (data) => {
        if(data.judgmentDate != null && data.judgmentDate instanceof Date)
			data.judgmentDate = [parseInt(data.judgmentDate.getFullYear()), + parseInt(('0' + (data.judgmentDate.getMonth() + 1)).slice(-2)), parseInt(('0' + data.judgmentDate.getDate()).slice(-2))];
        return data;
    }

    openSidePanel = (id) => {
        let Form = createFormComponent(registreOppositionFields);
        this.props.gui.openSidePanel(<Form loadData={async () => this.loadFormData(id)} id={id} onSubmit={(data) => update(data).then(() => {
            this.props.gui.closeSidePanel();
      //      this.props.obj.persist(this.form2persist(data));
        })} buttons={(whoami().roles.includes('GREFFIER_OA'))?()=>null:this.getButtons} />, this.props.title);
    }

    openEdit = () => {
        this.openSidePanel(this.state.data.id)
    }

    getButtons = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <button style={{ minWidth: '5rem' }} type="submit">
                            {t`Submit`}
                        </button>
                    </div>
                </div>
            </>
        )
    }
    
    render() {
        return (
            <>

            {(this.state.data)?
            <div className="rv5info" style={{margin: 'auto', marginBottom: '15px'}}>
            <div class="row">
            <div class="results col-md-11" style={{paddingLeft:"2rem"}}><h2>{t(this.props.title)}</h2></div>
            {(!this.props.readOnly) && <div class="col-md-1">
            <IconButton aria-label={t`Edit`} onClick={() => this.openEdit()}>
                <EditIcon />
            </IconButton>
            </div>}
            </div>    
            <div class="row" style={{paddingLeft:"1rem", marginLeft: "0px"}}>
            {this.buildBody()}
            <div class="results" style={{marginRight: "10px", marginLeft: "auto"}}>
                {this.buildFooter()}
            </div>
            </div>

            </div>:
            <div className="ValidationError">{t(this.props.title) + ' ' + t`not found`}</div>
            }
            </>
        )    
    }
}
