import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import { CAN_I_SWITCH } from "../../../../auto/js/events/Gui";
import { pojoMetadata, getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification, swapObject } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import { customMandatDepotForm2Dto, customMandatDepotDto2Form, getContext } from "../../../../main/js/forms/mandatDepot/MandatDepotFormCommon";
import * as Yup from 'yup';
import {
    birthPlaceComponentHook,
	citizenshipComponentHook,
	addressComponentHook,
	printMandatDepot
} from "../../../../main/js/forms/mandatDepot/MandatDepotFormCommon";


export const mandatDepotFields = [
    {
        name: "id",
        label: "id",
        type: "number",
        x: "1",
        y: "1",
        layout: "col-md-6",
    },
    {
        name: "firstname",
        label: "firstname",
        type: "text",
        x: "1",
        y: "2",
        layout: "col-md-6",
    },
    {
        name: "lastname",
        label: "lastname",
        type: "text",
        x: "2",
        y: "2",
        layout: "col-md-6",
    },
    {
        name: "birthdate",
        label: "birthdate",
        type: "date",
        x: "1",
        y: "3",
        layout: "col-md-6",
    },
    {
        name: "birthBoxBox",
        label: "birthBox",
        type: "box",
        x: "1",
        y: "4",
        layout: "col-md-12",
        components: [
            {
                name: "birthPlace",
                layout: "col-md-12",
                type: "custom",
                x: "1",
                y: "1",
                component: (name, disabled) => birthPlaceComponentHook(name, disabled),
            }
        ],
    },
    {
        name: "citizenship",
        label: "citizenship",
        type: "custom",
        x: "1",
        y: "5",
        layout: "col-md-6",
        component: (name, disabled) => citizenshipComponentHook(name, disabled),
    },
    {
        name: "profession",
        label: "profession",
        type: "text",
        x: "2",
        y: "5",
        layout: "col-md-6",
    },
    {
        name: "addressBoxBox",
        label: "addressBox",
        type: "box",
        x: "1",
        y: "6",
        layout: "col-md-12",
        components: [
            {
                name: "address",
                layout: "col-md-12",
                type: "custom",
                x: "1",
                y: "1",
                component: (name, disabled) => addressComponentHook(name, disabled),
            }
        ],
    },
    {
        name: "chargedWith",
        label: "chargedWith",
        type: "text",
        x: "1",
        y: "7",
        layout: "col-md-6",
    },
    {
        name: "articles",
        label: "articles",
        type: "text",
        x: "1",
        y: "9",
        layout: "col-md-6",
    },
    {
        name: "flagrantDelits",
        label: "flagrantDelits",
        type: "date",
        x: "1",
        y: "8",
        layout: "col-md-6",
    },
    {
        name: "citationDirect",
        label: "citationDirect",
        type: "date",
        x: "2",
        y: "8",
        layout: "col-md-6",
    },
    {
        name: "codePenal",
        label: "codePenal",
        type: "text",
        x: "2",
        y: "9",
        layout: "col-md-6",
    }
]


export const form2dto = (formData, dto) => {
    if (formData.id != null) {
        dto.id = parseInt(formData.id)
    }
    if (formData.birthdate != null && typeof(formData.birthdate) != 'string') {
        let birthdateAsDate = new Date(formData.birthdate);
        dto.birthdate = birthdateAsDate.getFullYear() + "-" + ('0' + (birthdateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + birthdateAsDate.getDate()).slice(-2);
    }
    if (formData.flagrantDelits != null && typeof(formData.flagrantDelits) != 'string') {
        let flagrantDelitsAsDate = new Date(formData.flagrantDelits);
        dto.flagrantDelits = flagrantDelitsAsDate.getFullYear() + "-" + ('0' + (flagrantDelitsAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + flagrantDelitsAsDate.getDate()).slice(-2);
    }
    if (formData.citationDirect != null && typeof(formData.citationDirect) != 'string') {
        let citationDirectAsDate = new Date(formData.citationDirect);
        dto.citationDirect = citationDirectAsDate.getFullYear() + "-" + ('0' + (citationDirectAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + citationDirectAsDate.getDate()).slice(-2);
    }
    customMandatDepotForm2Dto(formData, dto);
}

export const dto2form = (dto, token) => {
    let form =  _.clone(dto);
    if (dto.birthdate !== null) {
        form.birthdate = new Date().setFullYear(dto.birthdate[0], dto.birthdate[1], dto.birthdate[2]);
    }
    if (dto.flagrantDelits !== null) {
        form.flagrantDelits = new Date().setFullYear(dto.flagrantDelits[0], dto.flagrantDelits[1], dto.flagrantDelits[2]);
    }
    if (dto.citationDirect !== null) {
        form.citationDirect = new Date().setFullYear(dto.citationDirect[0], dto.citationDirect[1], dto.citationDirect[2]);
    }
    customMandatDepotDto2Form(dto, form, token);
    return form;
}

const getButtons = (data, onFinish) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    
                        <button style={{ minWidth: '5rem' }} className={'reject-button'} type="button" onClick={(onFinish) ? () => printMandatDepot(data).then(() => onFinish()) : () => printMandatDepot(data)}>
                            {t`Imprimer`}
                        </button>
                    
                </div>
            </div>
        </>
    )
}

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
	    let MandatDepotForm = createFormComponent(mandatDepotFields);
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<MandatDepotForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={(data) => getButtons(data, this.props.onFinish)} />
		</>
		)
	}
}

export const displayNewMandatDepotSidePanel = (gui, onFinish, formData) => {
	const readNewMandatDepotSidePanel = (onFinish) => (id) => {
        let uuid = uuidv4();
           gui.openSidePanel(<FormComponent key={uuid} onFinish={onFinish} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>)
    }
    let data;
    if (!formData) {
        data = buildEmptyObject(mandatDepotFields);
        data.draft = true;
        save(data).then((response) => {
            readNewMandatDepotSidePanel(onFinish)(response)
        });
    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            readNewMandatDepotSidePanel(onFinish)(response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    readNewMandatDepotSidePanel(onFinish)(response)
                });
            }
        } else {
            save(data).then((response) => {
                readNewMandatDepotSidePanel(onFinish)(response)
            });
        }
    }
}

export const displayNewMandatDepotForm = (gui, onFinish, formData) => {
	const readNewMandatDepotForm = (onFinish) => (id) => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then((response) => onFinish(response)):update} id={id}/>, getContext: (getContext) && getContext
        };

    }
    let data;
    if (!formData) {
        data = buildEmptyObject(mandatDepotFields);
        data.draft = true;
        save(data).then((response) => {
            gui.goTo(readNewMandatDepotForm(onFinish), response)
        });

    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            gui.goTo(readNewMandatDepotForm(onFinish), response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    gui.goTo(readNewMandatDepotForm(onFinish), response)
                });
            }
        } else {
            save(data).then((response) => {
                gui.goTo(readNewMandatDepotForm(onFinish), response)
            });
        }
    }
}

export const displayReadMandatDepotForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
	};
}
export const displayReadMandatDepotSidePanel = (gui, id, onFinish) => {
	const readReadMandatDepotSidePanel = (onFinish) => (id) => {
		let uuid = uuidv4();
		gui.openSidePanel(<FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>);
	}
	readReadMandatDepotSidePanel(onFinish)(id);
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
            case ("image"):
                empty[field.name] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true};
                break;
		}
	}
	return empty;
}

export const loadFormData = async (id) => {
    let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	return await rest.read('mandat-depot', id).then(response => {
		let form = dto2form(response, token);
		return form;
	})
}

export const save = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['mandat-depot'].form2dto(data);
	form2dto(formData, dto);
	try {
        return rest.request(getServiceUri() + 'mandat-depot/new-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
				return response;
		});
    } catch (err) {
        alert(err);
    }
}

export const update = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['mandat-depot'].form2dto(data);
	form2dto(formData, dto)
	try {
        return rest.request(getServiceUri() + 'mandat-depot/edit-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated mandatDepot`, "success");
		});
    } catch (err) {
        alert(err);
    }
}