import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import merge from 'lodash/merge';
import { Ribbon, RibbonGroup, RibbonGroupItem, RibbonButton } from "react-bootstrap-ribbon";
import { i18ndb } from '../services';
import { UPDATE_RIBBON_EVENT } from '../events/Gui'

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-ribbon/dist/react-bootstrap-ribbon.css";
import './ribbon.css'
import '../pages/Form.css'
import { menu } from '../../../main/js/router/Menu';
import { useConstructor } from '../utils';

const buildOptions = (options, gui) => {
    let items = [];
    let colClass = "col-" + Math.round(12/Object.entries(options).length)
    Object.entries(options).forEach(([option, descriptor]) => {
        items.push(
        <RibbonGroupItem colClass={colClass} key={option}>
            <RibbonButton onClick= {() => descriptor.do(gui)} >
				{descriptor.icon ? descriptor.icon : ""}
                <div>{i18ndb._(descriptor.label)}</div>
            </RibbonButton>
        </RibbonGroupItem>
        )  
    });
    
    return items;
}

const buildGroups = (subMenu, gui) => {
    let items = [];
    const subMenuKeys = Object.keys(subMenu);
    let colClass = "col-" + Math.round(12/subMenuKeys.length);
    subMenuKeys.forEach((key, index) => {
        let options = buildOptions(subMenu[key].options, gui)
        items.push(
            <RibbonGroup title={i18ndb._(subMenu[key].label)} key={key} colClass={colClass}>
                {options}
            </RibbonGroup>
        )
        
    });
    return items;
}

const buildMenu = (options, gui) => {
  const items = []
  const optionsKeys = Object.keys(options);
  optionsKeys.forEach((key, index) => {
    if (options[key]["submenu"]) {
        items.push(
            <Tab eventKey={key} title={i18ndb._(options[key].label)} key={key}>
              <RibbonComponent subMenu={options[key]["submenu"]} gui={gui} />
            </Tab>
          )
    }
  });
	return items;
}

const RibbonComponent = (props) => {
    const groups = buildGroups(props.subMenu, props.gui)
    return (
        <Ribbon breakpoint="lg">
            {groups}
        </Ribbon>
    )
}

export function RibbonTab({gui, menuSection}) {
    const [key, setKey] = useState(Object.keys(menuSection)[0]);
    const [ribbon, setRibbon] = useState(menuSection);

    useConstructor(() => {
        if (menuSection[key].do) {
            menuSection[key].do(gui);
        }
    })

    const setRibbonState = (m) => {
        setKey(Object.keys(m)[0])
        setRibbon(m)
    }

	UPDATE_RIBBON_EVENT.subscribe( (m)=>setRibbonState(m) );
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => {
            setKey(k)
            if (ribbon[key].do) {
                ribbon[key].do(gui);
            }
        }}
      >
		{buildMenu(ribbon, gui)}
      </Tabs>
    );
}

export function setContextualOptions(options) {
    if (Object.keys(options).length) {
        let m = merge(menu()[Object.keys(options)[0]], options[Object.keys(options)[0]]);
        UPDATE_RIBBON_EVENT.publish(m);
    }
}

export function cleanContextualOptions(options) {
    if (Object.keys(options).length) {
        let m = menu()[Object.keys(options)[0]];
        UPDATE_RIBBON_EVENT.publish(m);
    }
}