import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { AlertDialog } from "../../widgets";
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { registreAffectationFields, loadFormData} from "./NewRegistreAffectationForm";
import {
    
} from "../../../../main/js/forms/registreAffectation/RegistreAffectationFormCommon";

let RegistreAffectationForm = createFormComponent(registreAffectationFields);

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }
	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}
	render() {
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<RegistreAffectationForm  ref={this.myRef} key={this.props.key} loadData={async () => loadFormData(this.props.id)} id={this.props.id} buttons={() => null} />
		</>
		)
	}
}

export const displayReadAssignedRegistreAffectationForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} onFinish={onFinish} />
	};
}

