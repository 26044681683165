export const ComplainantContactPreferences = {
	"EMAIL": 1
	,
	"PHONE": 2
	
}
Object.freeze(ComplainantContactPreferences);
const toTypeMap = {};
Object.keys(ComplainantContactPreferences).forEach(key => {
	toTypeMap[ComplainantContactPreferences[key]] = key;
});
export function getComplainantContactPreferences (value) {
	return toTypeMap[value];
}

export function getAllComplainantContactPreferences () {
    return Object.keys(ComplainantContactPreferences);
}

export const COMPLAINANT_CONTACT_PREFERENCES_EMAIL = "EMAIL";
export const COMPLAINANT_CONTACT_PREFERENCES_PHONE = "PHONE";
