import React, { useState } from "react";
import { useField } from "formik";
import LoadingOverlay from 'react-loading-overlay-ts';
import { t } from "../../../auto/js/services";


export const EventComponent = ({...props}) => {
    const [value, setValue] = useState(props.id)


    return(
      <Event loadData={props.loadData}  title={props.title} actions={props.actions} columns={props.columns} footerElements={props.footerElements}/>
      )
}

class Event extends React.Component {
    constructor(props) {
      super(props);
      this.formRef = React.createRef()
      this.state = {
        data: undefined,
        loading: true,
        loadingMessage: ''
      }
      this.props.loadData().then((data) => {
        //formState.setState(data);
        this.setState({ data: data, loading: false })
      });
	  }

    componentDidUpdate(prevProps) {
      this.props.loadData().then((data) => {
        //formState.setState(data);
        if (data.id !== this.state.data.id)
          this.setState({ data: data, loading: false })
      });
    }

    buildBody = () => {
        let items = [];
        let colClass = "col-" + Math.round(12/this.props.columns.length)
        this.props.columns.forEach(element => {
            items.push(
                <div class={colClass}>
                    <div class="results">
                        <h4>{t(element)}</h4>
                        {this.state.data[element]}
                    </div>
                </div>
            )
        });
        return items;
    }

    buildFooter = () => {
        let items = [];
        this.props.footerElements.forEach(element => {
            <p style={{fontStyle: "italic", color: "#898c8e"}}>{t(element)}: {this.state.data[element]}</p>
        });
        return items;
    }

    render() {
 
        return (
            <>
            <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text={this.state.loadingMessage}
            >
            {(this.state.data)?
            <div className="rv5info">
            <div class="results" style={{paddingLeft:"1rem"}}><h2>{t(this.props.title)}</h2></div>   
            <div class="row" style={{paddingLeft:"1rem"}}>
            {this.buildBody()}
            <div class="results" style={{marginRight: "10px", marginLeft: "auto"}}>
                {this.buildFooter()}
            </div>
            </div>
            </div>:
            <div className="ValidationError">{t(this.props.title) + ' ' + t`not found`}</div>
            }
            </LoadingOverlay>
            </>
        )    
    }
}