import React, {useState} from 'react';
import {v4 as uuidv4} from 'uuid';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import merge from 'lodash/merge';
import { Ribbon, RibbonGroup, RibbonGroupItem, RibbonButton } from "react-bootstrap-ribbon";
import { menu } from '../../../main/js/router/Menu';
import { i18ndb } from '../services';
import { UPDATE_RIBBON_EVENT } from '../events/Gui'

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-ribbon/dist/react-bootstrap-ribbon.css";
import '../pages/Form.css'
import { RibbonBrowser } from '../browsers/RibbonBrowser';
import { Welcome } from '../../../main/js/pages/Welcome';
import ActionAreaCard from '../widgets/ActionAreaCard';

var supportedLanguages = [{"code":"en","label":"English"},{"code":"ru","label":"Russian"},{"code":"tg","label":"Tajik"}];

export const displayRibbon = () => (menu) => {
    let uuid = uuidv4();
    return { 
        uuid, view: () => <RibbonBrowser menuSection={menu} supportedLanguages={supportedLanguages} welcome={(uuid)=><Welcome key={uuid}/>}/>    }
}

const buildMenu = (options, gui) => {
  const items = []
  const optionsKeys = Object.keys(options);
  let colClass = "col-" + Math.round(12/optionsKeys.length)
  optionsKeys.forEach((key, index) => {
    items.push(
      <div className={options[key].layout} style={{marginBottom: '20px'}}>
        <ActionAreaCard onClick={() => gui.goTo(displayRibbon(), options[key])} title={i18ndb._(options[key].label)}/>
      </div>
    )
  });
	return items;
}

export function WelcomePage({gui}) {
    const [perspective, setPerspective] = useState(menu());
    return (
      <div className="row" style={{
        margin: '0',
        position: 'absolute',
        top: '25%',
        left: '50%',
        transform: 'translate(-41%, 50%)',
        width: '100%'
      }}>
		  {buildMenu(perspective, gui)}
      </div>
    );
}

