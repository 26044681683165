import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { grey } from "@mui/material/colors";
import { getServiceUri } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services";
import { displayCaseDataTimeline } from "../../timelines/CaseDataTimeline";

export const getRowActions = (rowData) => {
    return (
        <Tooltip>
            <IconButton 
                onClick={(event) => {
                    displayCaseDataTimeline(rowData.gui, rowData.folderId);
                }}
                size="small"
                sx={{color: grey[900]}}
            >
                <VisibilityIcon />
            </IconButton>
        </Tooltip>
    )
}

export const getOrderBy = () => {
    return "folder_id"
}

export const getDataEndpoint = async (filterData)  => {
    let searchEndpoint = (filterData.query) ? "registre-affectation/advanced-search" : "apply/search-registre-affectation";
    return await rest.request(getServiceUri() + searchEndpoint, "POST", filterData);
}

export const countDataEndpoint = async (filterData) => {
    let countEndpoint = (filterData.query) ? "registre-affectation/count/advanced-search" : "apply/count-registre-affectation";
    return await rest.request(getServiceUri() + countEndpoint, "POST", filterData);
}

export const filterDataLogic = (defaultRows) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        if (row.mtlbStatus != null)
            row.caseStatus = t(row.mtlbStatus);
        let plaignants = '';
        let victimes = '';
        let misCauses = '';
        if (row.plaignants.length)
            plaignants = row.plaignants.join(', ');
        if (row.victimes.length)
            victimes = row.victimes.join(', ');
        if (row.misCause)
            misCauses = row.misCause.join(', ');
        row.plaignants = plaignants;
        row.victimes = victimes;
        row.misCause = misCauses;
        newRows.push(row);
    }
    return newRows;
}