import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import { CAN_I_SWITCH  } from "../../../../auto/js/events/Gui";
import { t } from "../../../../auto/js/services";
import { showNotification } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import { customAudienceForm2Dto, customAudienceDto2form } from "../../../../main/js/forms/audience/AudienceFormCommon.jsx";
import DateTimePicker from "../../../../main/js/widgets/DateTimePicker";
import { getDjServicesUrl } from "../../utils";
import { servicesRest } from "../../services/ServicesRestClient";

export const audienceFields = [
    {
        name: "calendar", type: "custom", x: 1, y: 1, layout: "col-md-6", component: (name, disabled) => <DateTimePicker name={name} />

    }
]

export const form2dto = (formData, dto) => {
    if (formData.calendar) {
        if (formData.calendar.time != null && typeof(formData.calendar.time) != 'string') {
            const timeDate = new Date(formData.calendar.time)
            let timeHour = timeDate.getHours() < 10 ? '0' + timeDate.getHours() : timeDate.getHours();
            let timeMinutes = timeDate.getMinutes() < 10 ? '0' + timeDate.getMinutes() : timeDate.getMinutes();
            dto.time = timeHour + ":" + timeMinutes;
        }
        if (formData.calendar.date != null && typeof(formData.calendar.date) != 'string') {
            let dateAsDate = new Date(formData.calendar.date);
            dto.date = dateAsDate.getFullYear() + "-" + ('0' + (dateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + dateAsDate.getDate()).slice(-2);
        }
    }
    
    customAudienceForm2Dto(formData, dto);
}

export const dto2form = (dto) => {
    let form = dto;
    form.calendar = null;
    if (dto.date !== null) {
        form.calendar = {}
        form.calendar.date = new Date(dto.date[0] + "-" + dto.date[1] + "-" + dto.date[2]);
        if (dto.time !== null) {
            let h = (dto.time[0] < 10)?'0'+dto.time[0]:dto.time[0];
            let m = (dto.time[1] < 10)?'0'+dto.time[1]:dto.time[1];
            form.calendar.time = new Date("2017-05-29" + "T" + h + ":" + m + ":" + "00");
        }
    }
    
    customAudienceDto2form(dto, form);
    return form;
}

const getButtons = (id, onFinish) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <button style={{ minWidth: '5rem' }} type="submit">
                        {t`Submit`}
                    </button>
                </div>
            </div>
        </>
    )
}

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
	    let AudienceForm = createFormComponent(audienceFields);
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<AudienceForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={getButtons} />
		</>
		)
	}
}

export const displayNewAudienceSidePanel = (gui, formData, onFinish) => {
	const readNewAudienceSidePanel = (onFinish) => (id) => {
        let uuid = uuidv4();
        gui.openSidePanel(<FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>, t`Programmer une audience`)
    }
    let data;
    if (!formData) {
        data = buildEmptyObject(audienceFields);
        data.draft = true;
        save(data).then((response) => {
            readNewAudienceSidePanel(onFinish)(response);
        });
    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);   
        save(data).then((response) => {
            readNewAudienceSidePanel(onFinish)(response);
        });

    }
}

export const displayNewAudienceForm = (gui, formData) => {
	const readNewAudienceForm = (onFinish) => () => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(audienceFields)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update}/>
        };
    }
    let data;
    if (!formData) {
        data = buildEmptyObject(audienceFields);
        data.draft = true;
        save(data).then((response) => {
            gui.goTo(readNewAudienceForm(), response)
        });
    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            gui.goTo(readNewAudienceForm(), response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    gui.goTo(readNewAudienceForm(), response)
                });
            }
        } else {
            save(data).then((response) => {
                gui.goTo(readNewAudienceForm(), response)
            });
        }
    }
}

export const displayReadAudienceSidePanel = (gui, id, onFinish) => {
	let uuid = uuidv4();
	gui.openSidePanel(<FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>, t`Programmer une audience`)
}

export const displayReadAudienceForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
	};
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
            case ("image"):
                empty[field.name] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true};
                break;
		}
	}
	return empty;
}

export const loadFormData = async (id) => {
	return await servicesRest.read('audience', id).then(response => {
		let form = dto2form(response);
        return form
	})
}

const save = async (formData) => {
	let data = _.clone(formData);
	form2dto(formData, data);
	try {
        return servicesRest.request(getDjServicesUrl() + 'audience/new-document', 'POST', data).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
            return response;
		});
    } catch (err) {
        alert(err);
    }
}

export const update = async (formData) => {
	let data = _.clone(formData);
	form2dto(formData, data)
	try {
        return servicesRest.request(getDjServicesUrl() + 'audience/edit-document', 'POST', data).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
		});
    } catch (err) {
        alert(err);
    }
}
