import * as React from 'react';
import { useField } from "formik";
import { Observable } from '../../../auto/js/events/Observable';
import { geoDataMetadataLoader } from '../../../auto/js/metadata/GeoDataMetadataLoader';
import { t } from '../../../auto/js/services';
import { SimpleAutoCompleteInput } from '../../../auto/js/widgets/SimpleAutoCompleteInput';


export function GeoDataComponent({ ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [loaded, setLoaded] = React.useState(false);
    const [hideRegion, setHideRegion] = React.useState(true);
    const [hideDistrict, setHideDistrict] = React.useState(true);
	const instanceSpecificConstant = React.useRef({
		countryObservable: new Observable(),
		resetRegionEvent: new Observable(),
		regionObservable: new Observable(),
		resetDistrictEvent: new Observable(),
	});
	let resetList =[
		instanceSpecificConstant.current.resetRegionEvent,
		instanceSpecificConstant.current.resetDistrictEvent,
	];
	
    let countryValue;
    if (props.default && !loaded && value == null) {
        let country = geoDataMetadataLoader.getArea(props.default);
        countryValue = { key: country.id, value: country.name };
        if (country) {
            instanceSpecificConstant.current.countryObservable.publish(geoDataMetadataLoader.getChilds(country.id))
            if (Object.keys(geoDataMetadataLoader.getChilds(country.id)).length)
                setHideRegion(false);
            else
                setHideRegion(true);
        }
        setValue(geoDataMetadataLoader.getAreaId(country.id));
        setLoaded(true)
    }

    const handleCountryChange = (value) => {
        if (value.key) {
            instanceSpecificConstant.current.countryObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideRegion(false);
            else
                setHideRegion(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            instanceSpecificConstant.current.countryObservable.publish([])
            setHideRegion(true);
        }
        resetFieldsFromIndex(0);
    }
    let regionValue;

    const handleRegionChange = (value) => {
        if (value.key) {
            instanceSpecificConstant.current.regionObservable.publish(geoDataMetadataLoader.getChilds(value.key))
            if (Object.keys(geoDataMetadataLoader.getChilds(value.key)).length)
                setHideDistrict(false);
            else
                setHideDistrict(true);
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
        else {
            instanceSpecificConstant.current.regionObservable.publish([])
            setHideDistrict(true);
        }
        resetFieldsFromIndex(1);
    }
    let districtValue;

    const handleDistrictChange = (value) => {
        if (value.key) {
            setValue(geoDataMetadataLoader.getAreaId(value.key));
        }
    }
    const showData = (value) => {
	    let addressComponents = value.split(".")
	    if (addressComponents[0]) {
	        let country = geoDataMetadataLoader.getArea(addressComponents[0]);
	        if (country) {
	        	countryValue = { key: country.id, value: country.name };
		        instanceSpecificConstant.current.countryObservable.publish(geoDataMetadataLoader.getGeoLevel(country.id, 1))
		        if (hideRegion)
		        	setHideRegion(false);
	        }
	    }
	    if (addressComponents[1]) {
	        let region = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1]);
	        if (region) {
	        	regionValue = { key: region.id, value: region.name };
		        instanceSpecificConstant.current.regionObservable.publish(geoDataMetadataLoader.getGeoLevel(region.id, 2))
		        if (hideDistrict)
		        	setHideDistrict(false);
	        }
	    }
	    if (addressComponents[2]) {
	        let district = geoDataMetadataLoader.getArea(addressComponents[0] + "." + addressComponents[1] + "." + addressComponents[2]);
	        if (district) {
	        	districtValue = { key: district.id, value: district.name };
	        }
	    }
	}
    const resetFieldsFromIndex = (index) => {
	    for (let i = index; i < resetList.length; i++)
	        resetList[i].publish()
	}
    if (value != null)
        showData(value);
    else if (props.default) {
        let country = geoDataMetadataLoader.getArea(props.default);
        countryValue = { key: country.id, value: country.name };
    }
    let style = (props.linear)?"row":"";
    return (
        <>
            <div class="mainform">
                <section class={"fullwidth " + style}>
                    	<SimpleAutoCompleteInput layout={(props.linear)?"col-md-4":""} name={"country"} options={() => geoDataMetadataLoader.getRootNodes()} label={t`country`} handleChange={(data) => handleCountryChange(data)}  defaultValue={countryValue} disabled={props.disabled}/>
                    	{(!hideRegion ||regionValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-4":""} name={"region"} options={() => ""} label={t`region`} handleChange={(data) => handleRegionChange(data)} observable={instanceSpecificConstant.current.countryObservable} reset={instanceSpecificConstant.current.resetRegionEvent} defaultValue={regionValue} disabled={props.disabled}/>}
                    	{(!hideDistrict ||districtValue) && <SimpleAutoCompleteInput layout={(props.linear)?"col-md-4":""} name={"district"} options={() => ""} label={t`district`} handleChange={(data) => handleDistrictChange(data)} observable={instanceSpecificConstant.current.regionObservable} reset={instanceSpecificConstant.current.resetDistrictEvent} defaultValue={districtValue} disabled={props.disabled}/>}
                </section>
            </div>
        </>
    )
}
