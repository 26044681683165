import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { grey } from "@mui/material/colors";
import { displayCaseDataTimeline } from "../../timelines/CaseDataTimeline";

export const getOrderBy = () => {
    return "ID"
}

export const getRowActions = (rowData) => {
    return (
        <Tooltip>
            <IconButton 
                onClick={(event) => {
                    displayCaseDataTimeline(rowData.gui, rowData.id)
                }}
                size="small"
                sx={{color: grey[900]}}
            >
                <VisibilityIcon />
            </IconButton>
        </Tooltip>
    )
}

export const filterDataLogic = (defaultRows) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        if (row.data) {
            let rp = JSON.parse(row.data).rpId;
            if (rp !== null)
                row.rp = rp
        }
        let plaignants = '';
        let victimes = '';
        let misCauses = '';
        if (row.plaignants.length) {
            let pFirst = true;
            row.plaignants.forEach(element => {
                if (pFirst) {
                    plaignants = element.firstname;
                    pFirst = false;
                } 
                else {
                    plaignants = plaignants.concat(', ', element.firstname)
                }
                if (element.lastname != null) 
                    plaignants = plaignants.concat(' ', element.lastname)
            });
        }

        if (row.victimes.length) {
            let vFirst = true;
            row.victimes.forEach(element => {
                if (vFirst) {
                    victimes = element.firstname;
                    vFirst = false;
                } 
                else {
                    victimes = victimes.concat(', ', element.firstname)
                }
                if (element.lastname != null) 
                    victimes = victimes.concat(' ', element.lastname)
            });
        }
        if (row.misCause.length) {
            let mFirst = true;
            row.misCause.forEach(element => {
                if (mFirst) {
                    misCauses = element.firstname;
                    mFirst = false;
                } 
                else {
                    misCauses = misCauses.concat(', ', element.firstname)
                }
                if (element.lastname != null) 
                    misCauses = misCauses.concat(' ', element.lastname)
            });
        }
        row.plaignants = plaignants;
        row.victimes = victimes;
        row.misCauses = misCauses;
        newRows.push(row);
    };
    return newRows;
}