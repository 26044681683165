import React from "react";
import * as Yup from 'yup';

import { MTLB_TYPE_COMPLAINT } from "../../../../auto/js/metadata/MtlbType";
import { ComplainantContactPreferences, COMPLAINANT_CONTACT_PREFERENCES_EMAIL } from "../../../../auto/js/metadata/ComplainantContactPreferences"
import { IncidentCategory, INCIDENT_CATEGORY_GOVERNMENTAL_CONCERN } from "../../../../auto/js/metadata/IncidentCategory"
import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";
import { getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { DELETE_ATTACHMENT_EVENT, PRINT_EVENT } from "../../../../auto/js/events/Gui";
import { Section } from "../../../../auto/js/widgets/Section";
import TemplateComponent from 'react-mustache-template-component';
import { setContextualOptions } from "../../../../auto/js/widgets";
import { createRecord, getRecordsOptions, readRecord } from "../../utils";
import AutocompleteDynamicListSelectorField from "../../widgets/AutocompleteDynamicListSelectorField";
import { whoami } from "../../../../auto/js/users/UserInfo";
import { formState } from "../../../../auto/js/forms/FormState";
import { displayCaseDataList } from "../../../../auto/js/lists/caseData/CaseDataList";
import { save, update } from "../../../../auto/js/forms/complaintMtlb/NewComplaintMtlbForm";
import { swapObject } from "../../../../auto/js/utils";

export const plaignantsComponentHook = (name, disabled) => {
	return <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Plaignants`} />
}

export const CaseComponentHook = (name, disabled) => {
	return <Section name={name} />
}

export const victimesComponentHook = (name, disabled) => {
	return <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Victimes`} />
}

export const misCausesComponentHook = (name, disabled) => {
	return <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Mis en Causes`} />
}

export const statusDisplay = () => {
	return false;
}

const printTemplate = (data) => () => {
	PRINT_EVENT.publish({
		content: <TemplateComponent template={data.template} data={data.templateData} />,
		copyParentStryle: false
	})
}

export const customComplaintMtlbForm2Dto = (formData, dto) => {
	if (formData.mtlbType === null || !formData.mtlbType)
		dto.mtlbType = MTLB_TYPE_COMPLAINT;
	if (formData.incidentCategory === "")
		dto.incidentCategory = null;
	if (formData.incidentCategory != null)
		dto.incidentCategory = swapObject(IncidentCategory)[formData.incidentCategory];
	if (formData.victimes || formData.misCauses || formData.plaignants)
		rest.purge('case-data/parties', formData.id).then(() => {
			if (formData.victimes) {
				Object.keys(formData.victimes).forEach(element => {
					let names = formData.victimes[element].split(' ');
					let lastname ="";
					let firstname = names[0];
					if (names.length > 1)
						lastname = names[names.length - 1];
					let data = {folderId: formData.id, recordsId: element, firstname: firstname, lastname: lastname}
					rest.create('victimes', data);
				});			
			}
			if (formData.misCauses) {
				Object.keys(formData.misCauses).forEach(element => {
					let names = formData.misCauses[element].split(' ');
					let lastname ="";
					let firstname = names[0];
					if (names.length > 1)
						lastname = names[names.length - 1];
					let data = {folderId: formData.id, recordsId: element, firstname: firstname, lastname: lastname}
					rest.create('mis-cause', data);
				});
			}
			if (formData.plaignants) {
				Object.keys(formData.plaignants).forEach(element => {
					let names = formData.plaignants[element].split(' ');
					let lastname ="";
					let firstname = names[0];
					if (names.length > 1)
						lastname = names[names.length - 1];
					let data = {folderId: formData.id, recordId: element, firstname: firstname, lastname: lastname}
					rest.create('plaignants', data);
				});
			}
			
		})	
}

export const customComplaintMtlbDto2Form = (dto, form) => {
	if (form.complainantIdCardNumber == 0)
		form.complainantIdCardNumber = null
	if (dto.complainantContactPreferences !== null)
		form.complainantContactPreferences = ComplainantContactPreferences[dto.complainantContactPreferences];

	let tagFilter = {};
	tagFilter["case-tags"] = {caseId: form.id};
	rest.search('case-tags', tagFilter).then((response) => {
		response.forEach(element => {
			if (element.key === "AFFECTATION_STATUS")
				formState.addState(element.key, element.value)
		});
	})
}

export const buildComplaintMtlbFormEmptyObject  = () => {
	return buildEmptyObject(complaintMtlbFields(whoami()));
}

export const newComplaintMtlbFormForm2Dto = (form, dto) => {
	
}

export const getContext = () => {
	return {
		"na": {
			"na": {
				submenu: {
						"actions": {
							options: {
								 new: { label: "Submit", do: (gui) => update(formState.getState().data).then(() => {
									displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]});
								}) },
							},
							label: "Actions"
						},
				}
			},
			label: "New Cases",
			do: (gui) => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]})
		}
	}
}