
import React from 'react';

import { OPEN_VIEW_EVENT, CLOSE_VIEW_EVENT } from '../../../auto/js/events/Gui';
import { cleanContextualOptions, setContextualOptions } from '../widgets';
import { Observable } from '../events/Observable';

const onWindowClose = (uuid, onClose) => {
	if (onClose)
		return () => onClose().then(CLOSE_VIEW_EVENT.publish(uuid));
	return () => CLOSE_VIEW_EVENT.publish(uuid);
}

export class WindowManager extends React.Component {


	constructor(props) {
		super(props);
        this.views = new Map();
        this.closeViewEvent = new Observable();
		this.closeViewEvent.subscribe((uuid) => this.closeView(uuid));
    }

	register = (v) => {
		this.views.set(v.uuid, v);
	}
	
	unregister = (uuid) => {
		this.views.delete(uuid);
	}

	open = (v) => {
		if (!this.views.has(v.uuid)) {
			this.register(v);
            if(v.getContext)
                setContextualOptions(v.getContext());
            this.props.publish(v);
		}
	}
	
	closeView = (uuid) => {
		if (this.views.has(uuid)) {
            if(this.views.get(uuid).getContext)
                cleanContextualOptions(v.getContext());
			this.unregister(uuid);
		}
	}
	
}