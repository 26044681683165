import React from 'react';

import {v4 as uuidv4} from 'uuid';
import {  setContextualOptions } from '../widgets';

import '@trendmicro/react-breadcrumbs/dist/react-breadcrumbs.css';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";

import { Talifoon } from '../events/Talifoon';

const welcomeUUID = uuidv4();

import { WindowManager } from './WindowManager';
import { Observable } from '../events/Observable';

export const createViewPortComponent = () => class ViewPort extends React.Component {
	constructor(props) {
		super(props)
		this.history = null;
		this.x=0;
		this.size = 0;
        this.limit = 100;
        this.views = new Map();
		this.requestingClose = false;
		this.welcome = {uuid: welcomeUUID, view: ()=>props.welcome(welcomeUUID)}
//		this.register(this.welcome);
		this.state = {
			layout: [],
			focusedView: {}
		};
		this.canISwitch = new Talifoon();
		this.openViewObservable = new Observable();
		this.openViewObservable.subscribe((v) => this.setState({focusedView: v}));
		this.wm = new WindowManager(this.openViewObservable);
    }

	readWelcomePage = () => {
		return this.welcome;
	}

	register = (v) => {
		this.views.set(v.uuid, v);
	}
	
	unregister = (v) => {
		setContextualOptions({});
		this.views.delete(v.uuid);
	}

	canISwitchTo = (v) => {
		if (this.state.focusedView) {
			this.requestingClose = true;
			this.canISwitch.send(this.doSwitchTo(v), () => this.doSwitchTo(v)(true));
		}
	}

	doSwitchTo = (v) => (closeCurrent) => {
		if (closeCurrent) {
			this.switchView(v);
		}
		this.requestingClose = false;
	}

	addToHistory = (data) => {
		// Adding data to the linked list history
		if (!this.history) {
		  this.history = { data, next: null };
		  this.size = 1;
		} else {
		  if (this.size === this.limit) {
			// Remove the oldest entry
			let current = this.history;
			this.history = current.next;
			this.size--;
		  }
		  let current = this.history;
		  while (current.next) {
			current = current.next;
		  }
		  current.next = { data, next: null };
		  this.size++;
		}
	}

	getHistoryBeforeLast = () => {
        // Retrieve the entry just before the last entry in the history
        if (!this.history || !this.history.next) {
          return null; // If there are 0 or 1 entries in history
        }
        let current = this.history;
        while (current.next && current.next.next) {
          current = current.next;
        }
        return current.data;
      }

	goTo = (cmd, args) => {
        let v = cmd(args);
        this.addToHistory({cmd, args});
        if (!this.requestingClose)
			this.canISwitchTo(v)
    }

	switchView = function (v) {
		// default behavior for now is one view at a time
		// this will eventually change though
		if (this.state.focusedView) {
			if (this.state.focusedView.uuid==v.uuid)
				return;
			else if (this.state.focusedView.area === v.area){
				this.unregister(this.state.focusedView);
				this.canISwitch.hangUp();
			}
		}
		if (!this.views.has(v.uuid)) {
			this.register(v);
			this.setState({focusedView:v});
        }
		this.requestingClose = false;
	}
	
	buildDesktop = () => {
        let Desktop;
        for (const [uuid, v] of this.views.entries()) {
		    Desktop = <>{v.view()}</>;
        }
        let WmViews = [];
		for (const [uuid, v] of this.wm.views.entries()) {
            WmViews.push(<>{v.view()}</>)
		}
        let Views = <>{(Desktop)?Desktop:null}{WmViews}</>
		return Views;
	}
	
}