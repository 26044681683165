import React from "react";
import { IconButton } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { t } from "../../../auto/js/services";
import { CLOSE_SIDE_PANEL, OPEN_SIDE_PANEL } from "../../../auto/js/events/Gui";
import { createFormComponent } from "../../../auto/js/widgets";
import { dto2form, jugementFields, update } from "../../../auto/js/forms/jugement/NewJugementForm";
import { whoami } from "../../../auto/js/users/UserInfo";

export const JugementSummary = ({...props}) => {
    return(
      <Jugement obj={props.obj} columns={['rpId', 'legacyId', 'numeroCopie']} footerElements= {['created', 'lastModified']} title={"Détails de jugement"} gui={props.gui}/>
      )
}

class Jugement extends React.Component {
    constructor(props) {
      super(props);
      this.formRef = React.createRef()
      this.state = {
        data: props.obj.data,
        loading: true,
        loadingMessage: ''
      }
      this.updateData = this.updateData.bind(this);
      props.obj.observable.subscribe(this.updateData)
    }

    buildBody = () => {
        let items = [];
        let colClass = "col-" + Math.round(12/this.props.columns.length)
        this.props.columns.forEach(element => {
            items.push(
                <div class={colClass}>
                    <div class="results">
                        <h4>{t(element)}</h4>
                        {this.state.data[element]}
                    </div>
                </div>
            )
        });
        return items;
    }

    updateData = (data) => {
        this.setState({data: data})
    }

    buildFooter = () => {
        let items = [];
        this.props.footerElements.forEach(element => {
            <p style={{fontStyle: "italic", color: "#898c8e"}}>{t(element)}: {this.state.data[element]}</p>
        });
        return items;
    }

    loadFormData = async () => {
        let form = dto2form(this.state.data);
        return form;
    }

    form2persist = (data) => {
        if(data.date != null && data.date instanceof Date)
			data.date = [parseInt(data.date.getFullYear()), + parseInt(('0' + (data.date.getMonth() + 1)).slice(-2)), parseInt(('0' + data.date.getDate()).slice(-2))];
        return data;
    }

    openSidePanel = (id) => {
        let Form = createFormComponent(jugementFields);
        this.props.gui.openSidePanel( <Form loadData={async () => this.loadFormData(id)} id={id} readOnly={(whoami().roles.includes('JUGE') || whoami().roles.includes('GREFFIER_CC'))?false:true} onSubmit={(data) => update(data).then(() => {
            this.props.gui.closeSidePanel();
            this.props.obj.persist(this.form2persist(data));
        })} buttons={(whoami().roles.includes('JUGE') || whoami().roles.includes('GREFFIER_CC'))?this.getButtons:()=>null} />, this.props.title);
    }

    openEdit = () => {
    this.openSidePanel(this.state.data.id)
    }

    getButtons = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <button style={{ minWidth: '5rem' }} type="submit">
                            {t`Submit`}
                        </button>
                    </div>
                </div>
            </>
        )
    }
    
    render() {
        return (
            <>

            {(this.state.data)?
            <div className="rv5info" style={{margin: 'auto', marginBottom: '15px'}}>
            <div class="row">
            <div class="results col-md-11" style={{paddingLeft:"2rem"}}><h2>{t(this.props.title)}</h2></div>
            {(!this.props.readOnly) && <div class="col-md-1">
            <IconButton aria-label={t`Edit`} onClick={() => this.openEdit()}>
                <EditIcon />
            </IconButton>
            </div>}
            </div>    
            <div class="row" style={{paddingLeft:"1rem", marginLeft: "0px"}}>
            {this.buildBody()}
            <div class="results" style={{marginRight: "10px", marginLeft: "auto"}}>
                {this.buildFooter()}
            </div>
            </div>
            </div>:
            <div className="ValidationError">{t(this.props.title) + ' ' + t`not found`}</div>
            }
            </>
        )    
    }
}
