import React from 'react';
import {v4 as uuidv4} from 'uuid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { t, rest } from '../services';

import toolbarStyles from './ToolBar.styl';
import { LanguageSelector } from '../widgets/LanguageSelector';

import { whoami } from '../users/UserInfo';
import { UserAvatar } from '../users/Avatars';
import { faSignOutAlt } from '@fortawesome/pro-regular-svg-icons';


import '../pages/Form.css';
import '../../css/form.css';
import { WelcomePage } from '../pages/WelcomePage';
import { createBrowserComponent } from '../browsers/Browser';

const displayWelcomePage = () => (gui) => {
  let uuid = uuidv4();
  return { 
      uuid, view: () => <div key={uuid}><WelcomePage gui={gui}/></div>
  }
}

export class ToolBar extends createBrowserComponent() {
  constructor(props) {
    super(props)
    this.state = {
        ...this.state,
    }
    this.goTo(displayWelcomePage(), this)
  }

render() {
  return (
    <div style={{ flexGrow: 1, height: '1500px' }}>
      <AppBar position="static">
        <Toolbar color="inherit" className={toolbarStyles.toolbar} >
      		<h3 style={{position: 'absolute', cursor: 'pointer'}} onClick={() => this.goTo(displayWelcomePage(), this)}>{t`Court`}</h3>
		        <img alt="logo.png" src="/public/logo.png" style={{maxWidth: '35px', marginRight: 'auto', marginTop: '30px'}}/>
            <LanguageSelector languages={this.props.languages}/>
			{UserAvatar(whoami())}
            <Button
            color="inherit"
            className={toolbarStyles.navbarBtn}
            onClick={() => {rest.oidcService.logout();}}>
          <FontAwesomeIcon icon={faSignOutAlt} style={{ fontSize: '1.75em' }} title={t`Sign out`} />
          </Button>
        </Toolbar>
      </AppBar>
      {this.buildDesktop()}
    </div>
  );
  }
}
