import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createTimelineWidgetsComponent } from "../widgets/TimelineWidgetsComponent";
import { rest } from "../../../auto/js/services";
import { loadCaseData } from "../utils";
import { CaseComponent } from "../widgets/CaseComponent";
import { loadComplaintMtlbFormData } from "../../../auto/js/forms/complaintMtlb/ComplaintMtlbApi";
import { whoami } from "../../../auto/js/users/UserInfo";
import { cleanContextualOptions, setContextualOptions } from "../../../auto/js/widgets";
import { displayNewSoitTransmisSidePanel } from "../../../auto/js/forms/soitTransmis/NewSoitTransmisForm";
import { displayNewRegistreOppositionSidePanel } from "../../../auto/js/forms/registreOpposition/NewRegistreOppositionForm";
import { displayCaseDataList } from "../../../auto/js/lists/caseData/CaseDataList";
import { displayNewMandatDepotSidePanel } from "../../../auto/js/forms/mandatDepot/NewMandatDepotForm";
import { displayNewMandatAmenerSidePanel } from "../../../auto/js/forms/mandatAmener/NewMandatAmenerForm";
import { displayReadJugementSidePanel } from "../../../auto/js/forms/jugement/NewJugementForm";
import { displayReadRegistreAffectationSidePanel } from "../../../auto/js/forms/registreAffectation/NewRegistreAffectationForm";
import { getServiceUri } from "../../../auto/js/metadata";
import { displayNewAudienceSidePanel } from "../forms/audience/NewAudienceForm";
import { displayReadRegistrePoursuiteSidePanel } from "../../../auto/js/forms/registrePoursuite/NewRegistrePoursuiteForm";
import { IncidentCategory } from "../../../auto/js/metadata/IncidentCategory";
import { servicesRest } from "../services/ServicesRestClient";

const caseTimelineFields = (whoami) => { return [
	{
		name: 'plaintDetails',
		title: "Entrée dans le registre d'arrivée",
		columns: ['subjectOfComplaint', 'incidentCategory', 'incidentDate', 'incidentTime', 'mtlbStatus'],
		loadData: async (id) => loadComplaintMtlbFormData(id),
		type: "EVENT",
		x:1,
		y:1,
		layout:"col-md-12",
		footerElements: ['created', 'lastModified']
	},
	{
		name: 'affectation',
		title: "Entrée dans le registre d'affectation",
		columns: [],
		loadData: async (id) => loadComplaintMtlbFormData(id),
		type: "EVENT",
		x:1,
		y:2,
		layout:"col-md-12",
		footerElements: ['created', 'lastModified']
	},
	{
		name: 'poursuite',
		title: "Entrée dans le registre de poursuite",
		columns: [],
		loadData: async (id) => loadComplaintMtlbFormData(id),
		type: "EVENT",
		x:1,
		y:3,
		layout:"col-md-12",
		footerElements: ['created', 'lastModified']
	},
	{
		name: 'soitTransmis',
		title: "Soit transmis",
		columns: [],
		loadData: async (id) => loadComplaintMtlbFormData(id),
		type: "EVENT",
		x:1,
		y:4,
		layout:"col-md-12",
		footerElements: ['created', 'lastModified']
	}
]}

class TimelineComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
    }

	loadTimelineData = async (id, gui) => {
		return await rest.read('case-data/read', id).then(response => {
			this.setContext(JSON.parse(response.data), id, gui)
			return response;
		});
	}
	
	setContext = async (data, id, gui) => {
		let tagFilter = {};
		tagFilter["case-tags"] = {caseId: id};
		rest.search('case-tags', tagFilter).then((response) => {
			response.forEach(element => {
				if (element.key === "AFFAIRE_STATUS")
					if (element.value === "PV_SUBMITTED") {
						let doc = data.documents.filter((doc) => doc.documentType === "REGISTRE_POURSUITE")[0];
						let affDoc = data.documents.filter((doc) => doc.documentType === "REGISTRE_AFFECTATION")[0];
						if (doc)
							setContextualOptions({
								"na": {
									"na": {
										submenu: {
											"actions": {
												label: "Actions",
												options: {
													...(whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('ALL')) && {
														"nouveau-st": {
															label: "Nouveau Soit Transmis",
															do: (gui) => displayNewSoitTransmisSidePanel(gui, () => {
																displayCaseDataTimeline(gui, id)
															}, {ra: id, destination: doc.registrePoursuite.unit})
														},
														"nouveau-md": {
															label: "Nouveau Mandat de dépôt",
															do: (gui) => displayNewMandatDepotSidePanel(gui, () => {
																displayCaseDataTimeline(gui, id)
															}, {caseId: id})
														}
													},
													...(!whoami().roles.includes('GREFFIER_PENAL_BO') && !whoami().roles.includes('GREFFIER_ADMIN')) && {
														"poursuivre": {
															label: "Poursuivre",
															do: (gui) => poursuivre(doc.registrePoursuite.id).then(() => {
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CASE_OPENED"]})
																cleanContextualOptions({"affaire-cours":{"affaire-cours":{}}})
															})
														},
														"classer": {
															label: "Classer",
															do: () => classer(doc.registrePoursuite.id).then(() => {
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CLOSED"]})
																cleanContextualOptions({"ac":{"ac":{}}})
															})
														}
													},
													...(whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('ALL')) && {
														"affecter": {
															label: "Affecter un procureur ou un substitut",
															do: (gui) => displayReadRegistreAffectationSidePanel(gui, affDoc.id,()=>{
																displayCaseDataTimeline(gui, id);
																gui.closeSidePanel();
															})
														}
													}
												}
											}
										},
										do: (gui) => {
											displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]})
											cleanContextualOptions({"na":{"na":{}}})
										}
									}
								}	
							})	
					}
					else if (element.value === "SUBMITTED") {
						let doc = data.documents.filter((doc) => doc.documentType === "REGISTRE_ARRIVEE")[0];
						let affDoc = data.documents.filter((doc) => doc.documentType === "REGISTRE_AFFECTATION")[0];
						if (doc)
							setContextualOptions({
								"na": {
									"na": {
										submenu: {
											"actions": {
												label: "Actions",
												options: {
													...(whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('ALL')) && {
														"nouveau-st": {
															label: "Nouveau Soit Transmis",
															do: (gui) => displayNewSoitTransmisSidePanel(gui, () => {
																displayCaseDataTimeline(gui, id)
															}, {ra: id})
														},
														"nouveau-md": {
															label: "Nouveau Mandat de dépôt",
															do: (gui) => displayNewMandatDepotSidePanel(gui, () => {
																displayCaseDataTimeline(gui, id)
															}, {caseId: id})
														}
													},
													...(!whoami().roles.includes('GREFFIER_PENAL_BO') && !whoami().roles.includes('GREFFIER_ADMIN')) && {
														"investigate": {
															label: "Enquêter",
															do: () => displayNewSoitTransmisSidePanel(gui, () => {
																investigate(doc.complaintMtlb.id).then(() => {
																	displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]})
																	cleanContextualOptions({"na":{"na":{}}})
																})
															}, {ra: id}) 	
														},
														"classer": {
															label: "Classer",
															do: () => classerAffaire(doc.complaintMtlb.id).then(() => {
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CLOSED"]})
																cleanContextualOptions({"ac":{"ac":{}}})
															})
														}
													},
													...(whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('ALL')) && {
														"affecter": {
															label: "Affecter un procureur ou un substitut",
															do: (gui) => displayReadRegistreAffectationSidePanel(gui, affDoc.id,()=>{
																displayCaseDataTimeline(gui, id);
																gui.closeSidePanel();
															})
														}
													}

												}
											}
										},
										do: (gui) => {
											displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]})
											cleanContextualOptions({"na":{"na":{}}})
										}
									}
								}	
							})
					}
					else if (element.value === "WAIT_FOR_PV") {
						let doc = data.documents.filter((doc) => doc.documentType === "REGISTRE_POURSUITE")[0];
						let affDoc = data.documents.filter((doc) => doc.documentType === "REGISTRE_AFFECTATION")[0];
						if (doc)
							setContextualOptions({
								"na": {
									"na": {
										submenu: {
											"actions": {
												label: "Actions",
												options: {
													...(whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('ALL')) && {
														"nouveau-st": {
															label: "Nouveau Soit Transmis",
															do: (gui) => displayNewSoitTransmisSidePanel(gui, () => {
																displayCaseDataTimeline(gui, id)
															}, {ra: id, destination: doc.registrePoursuite.unit})
														},
														"nouveau-md": {
															label: "Nouveau Mandat de dépôt",
															do: (gui) => displayNewMandatDepotSidePanel(gui, () => {
																displayCaseDataTimeline(gui, id)
															}, {caseId: id})
														}
													},
													"upload-pv": {
														label: "Téléverser un PV",
														do: (gui) => displayReadRegistrePoursuiteSidePanel(gui, doc.id, () => {
															displayCaseDataTimeline(gui, id);
															gui.closeSidePanel();
														})
													},
													...(whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('ALL')) && {
														"affecter": {
															label: "Affecter un procureur ou un substitut",
															do: (gui) => displayReadRegistreAffectationSidePanel(gui, affDoc.id,()=>{
																displayCaseDataTimeline(gui, id);
																gui.closeSidePanel();
															})
														}
													}
												}
											}
										},
										do: (gui) => {
											displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]})
											cleanContextualOptions({"na":{"na":{}}})
										}
									}
								}	
							})
					}
					else if (element.value === "JUDGED") {
						let doc = data.documents.filter((doc) => doc.documentType === "JUGEMENT")[0];
						if (doc)
							setContextualOptions({
								"aj": {
									"aj": {
										submenu: {
											"actions": {
												label: "Actions",
												options: {
													...(whoami().roles.includes('GREFFIER_OA') || whoami().roles.includes('ALL')) && {
													"opposition": {
														label: "Nouvel Acte d'Opposition",
														do: (gui) =>{
															let data = doc.jugement;
															data.judgmentNumber = doc.jugement.id;
															data.id = null;  
															data.judgmentDate = doc.jugement.date;
															data.devantNous = whoami().firstname + ' ' + whoami().lastname
															displayNewRegistreOppositionSidePanel(gui, ()=>{
																displayCaseDataTimeline(gui, id);
																gui.closeSidePanel();
															}, data)
														}
													},
													}
												}
											}
										},
										do: (gui) => {
											displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["JUDGED"]})
											cleanContextualOptions({"aj":{"aj":{}}})
										},
									}
								}	
							})
					}
					else if (element.value === "CASE_OPENED") {
							let jDoc = data.documents.filter((doc) => doc.documentType === "JUGEMENT")[0];
							let audience = data.documents.filter((doc) => doc.documentType === "AUDIENCE")[0];
							let rp = data.documents.filter((doc) => doc.documentType === "REGISTRE_POURSUITE")[0];
							if (jDoc)
								setContextualOptions({
									"affaire-cours": {
										"affaire-cours": {
											submenu: {
												"actions": {
													label: "Actions",
													options: {
														...(whoami().roles.includes('JUGE') || whoami().roles.includes('ALL') && audience) && {
															"amener": {
																label: "Nouveau Mandat d'amener",
																do: (gui) => {
																	servicesRest.read('audience', audience.id).then((response) => {
																		let date = response.date;
																		let hour = (response.time[0] > 9)?response.time[0]:'0' + response.time[0];
																		let minute = (response.time[1] > 9)?response.time[1]:'0' + response.time[1]; 
																		let mdFormData = {caseId: id, audienceDate: date, audienceHour: hour + ":" + minute}
																		mdFormData.president = whoami().lastname + ' ' + whoami().firstname;
																		mdFormData.rp = rp.id;
																		mdFormData.pv = rp.registrePoursuite.pvId;
																		displayNewMandatAmenerSidePanel(gui, () => {
																			displayCaseDataTimeline(gui, id);
																		}, mdFormData)
																	})
																}
															},
														},
														...(whoami().roles.includes('GREFFIER_CC') || whoami().roles.includes('JUGE') || whoami().roles.includes('ALL')) && {
															"audience": {
																label: "Programmer une audience",
																do: (gui) => displayNewAudienceSidePanel(gui, {folderId: id}, () => {
																	displayCaseDataTimeline(gui, id);
																	gui.closeSidePanel();
																})
															}
														},
														...(whoami().roles.includes('JUGE') || whoami().roles.includes('ALL')) && {
														"jugement": {
															label: "Téléverser un jugement",
															do: (gui) => displayReadJugementSidePanel(gui, jDoc.id, ()=>{
																displayCaseDataTimeline(gui, id);
																gui.closeSidePanel();
															})
														},
														}
														}
													}
												},
												do: (gui) => {
													displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CASE_OPENED"]})
													cleanContextualOptions({"affaire-cours":{"affaire-cours":{}}})
												},
											}
										}
									}	
								)
						}

	
			});
		})
	}


	render() {
		let CaseTimeline = createTimelineWidgetsComponent(caseTimelineFields(whoami()));

		return (
		<>
			<CaseComponent name={name} disabled={true} loadData={() => loadCaseData(this.props.id)} />
			<CaseTimeline gui={this.props.gui} ref={this.myRef} key={this.props.key} loadData={async () => this.loadTimelineData(this.props.id, this.props.gui)} />
		</>
		)
	}

}


export const displayCaseDataTimeline = (gui, id)=> {
		 const readCaseDataTimeline = (onFinish) => (id) => {
		let uuid = uuidv4();
		return {
			uuid, view: () => <TimelineComponent key={uuid} gui={gui} id={id} onFinish={onFinish}/>
		};
	}
	gui.goTo(readCaseDataTimeline(), id) 
}

const poursuivre = async (id) => {
    try {
        return rest.request(getServiceUri() + 'apply/poursuivre', 'POST', {id:id});
    } catch (err) {
        alert(err);
    }
}

const classer = async (id) => {
    try {
        return rest.request(getServiceUri() + 'apply/classer', 'POST', {id:id});
    } catch (err) {
        alert(err);
    }
}

const investigate = async (id) => {
    try {
        return rest.request(getServiceUri() + 'apply/investigate', 'POST', {id:id});
    } catch (err) {
        alert(err);
    }
}

const classerAffaire = async (id) => {
    try {
        return rest.request(getServiceUri() + 'apply/close-complaint', 'POST', {id:id});
    } catch (err) {
        alert(err);
    }
}