import React from "react"
import TemplateComponent from "react-mustache-template-component"
import { PRINT_EVENT } from "../../../../auto/js/events/Gui"
import { rest } from "../../../../auto/js/services"
import { update } from "../../../../auto/js/forms/mandatAmener/NewMandatAmenerForm"

export const customMandatAmenerForm2Dto = (formData, dto) => {

}

export const customMandatAmenerDto2Form = (dto, form) => {

}

export const printMandatAmener = async (data) => {
    return update(data).then(async () => {
        return rest.read('forms/mandat-amener', data.id).then(async m => {
            printTemplate(m)
        })
    })
}

export const print = (id) => {
    rest.read('forms/mandat-amener', id).then(async m => {
        printTemplate(m)
    })
}

const printTemplate = (data) => {
	PRINT_EVENT.publish({
		content: <TemplateComponent template={data.template} data={dto2form(data.templateData)} />,
		copyParentStryle: false
	})
}

const dto2form = (dto) => {
	let form = dto; 
	if (dto.audienceDate != null)
		form.audienceDate = formatDate(dto.audienceDate);
	if (dto.date != null)
		form.date = formatDate(dto.date);
	return form;
}

const formatDate = (date) => {
	let d = (date[2] >= 10)?date[2]:"0"+date[2];
	let m = (date[1] >= 10)?date[1]:"0"+date[1];
	return d + "/" + m + "/" + date[0];
}