import { getDjServicesUrl } from "../utils";

class ServicesRestClient {

    search = async (subsection, filter) => {  
        let json = JSON.stringify(filter);
        const response = await fetch(getDjServicesUrl() + '/' + subsection + '/search/', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: json
        });
        return await response.json();
    }

    read = async (subsection, id) => {
		const response = await fetch(getDjServicesUrl() + '/' + subsection + '/' + id, {
			method: 'GET',
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer' // no-referrer, *client
		});
		return response.json();
	}

    request = async (uri, method, data) => {
		let json = JSON.stringify(data);
		const response = await fetch(uri, {
			method: method,
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json',
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'no-referrer', // no-referrer, *client
			body: (data)?json:null
		});
		return await response.json();
	}
}

export const servicesRest = new ServicesRestClient();