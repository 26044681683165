import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import { CAN_I_SWITCH } from "../../../../auto/js/events/Gui";
import { pojoMetadata, getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification, swapObject } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import { customRegistreAffectationForm2Dto, customRegistreAffectationDto2Form, getContext } from "../../../../main/js/forms/registreAffectation/RegistreAffectationFormCommon";
import * as Yup from 'yup';
import {
    folderIdDisplay,
	substitutIdCardComponentHook,
	idDisplay
} from "../../../../main/js/forms/registreAffectation/RegistreAffectationFormCommon";


export const registreAffectationFields = [
    {
        name: "folderId",
        label: "folderId",
        type: "number",
        x: "2",
        y: "1",
        layout: "col-md-6",
        display: (data) => folderIdDisplay(data),
    },
    {
        name: "substitutIdCard",
        label: "substitutIdCard",
        type: "custom",
        x: "1",
        y: "2",
        layout: "col-md-6",
        component: (name, disabled) => substitutIdCardComponentHook(name, disabled),
    },
    {
        name: "id",
        label: "id",
        type: "text",
        x: "1",
        y: "1",
        layout: "col-md-6",
        display: (data) => idDisplay(data),
    }
]


export const form2dto = (formData, dto) => {
    if (formData.folderId != null) {
        dto.folderId = parseInt(formData.folderId)
    }
    customRegistreAffectationForm2Dto(formData, dto);
}

export const dto2form = (dto, token) => {
    let form =  _.clone(dto);
    customRegistreAffectationDto2Form(dto, form, token);
    return form;
}

const getButtons = (data, onFinish) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    
                        <button style={{ minWidth: '5rem' }} className={'reject-button'} type="submit">
                            {t`Submit`}
                        </button>
                    
                </div>
            </div>
        </>
    )
}

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
	    let RegistreAffectationForm = createFormComponent(registreAffectationFields);
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<RegistreAffectationForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={(data) => getButtons(data, this.props.onFinish)} />
		</>
		)
	}
}

export const displayNewRegistreAffectationSidePanel = (gui, onFinish, formData) => {
	const readNewRegistreAffectationSidePanel = (onFinish) => (id) => {
        let uuid = uuidv4();
           gui.openSidePanel(<FormComponent key={uuid} onFinish={onFinish} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>)
    }
    let data;
    if (!formData) {
        data = buildEmptyObject(registreAffectationFields);
        data.draft = true;
        save(data).then((response) => {
            readNewRegistreAffectationSidePanel(onFinish)(response)
        });
    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            readNewRegistreAffectationSidePanel(onFinish)(response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    readNewRegistreAffectationSidePanel(onFinish)(response)
                });
            }
        } else {
            save(data).then((response) => {
                readNewRegistreAffectationSidePanel(onFinish)(response)
            });
        }
    }
}

export const displayNewRegistreAffectationForm = (gui, onFinish, formData) => {
	const readNewRegistreAffectationForm = (onFinish) => (id) => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then((response) => onFinish(response)):update} id={id}/>, getContext: (getContext) && getContext
        };

    }
    let data;
    if (!formData) {
        data = buildEmptyObject(registreAffectationFields);
        data.draft = true;
        save(data).then((response) => {
            gui.goTo(readNewRegistreAffectationForm(onFinish), response)
        });

    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            gui.goTo(readNewRegistreAffectationForm(onFinish), response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    gui.goTo(readNewRegistreAffectationForm(onFinish), response)
                });
            }
        } else {
            save(data).then((response) => {
                gui.goTo(readNewRegistreAffectationForm(onFinish), response)
            });
        }
    }
}

export const displayReadRegistreAffectationForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
	};
}
export const displayReadRegistreAffectationSidePanel = (gui, id, onFinish) => {
	const readReadRegistreAffectationSidePanel = (onFinish) => (id) => {
		let uuid = uuidv4();
		gui.openSidePanel(<FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>);
	}
	readReadRegistreAffectationSidePanel(onFinish)(id);
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
            case ("image"):
                empty[field.name] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true};
                break;
		}
	}
	return empty;
}

export const loadFormData = async (id) => {
    let token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	return await rest.read('registre-affectation', id).then(response => {
		let form = dto2form(response, token);
		return form;
	})
}

export const save = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['registre-affectation'].form2dto(data);
	form2dto(formData, dto);
	try {
        return rest.request(getServiceUri() + 'registre-affectation/new-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
				return response;
		});
    } catch (err) {
        alert(err);
    }
}

export const update = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['registre-affectation'].form2dto(data);
	form2dto(formData, dto)
	try {
        return rest.request(getServiceUri() + 'registre-affectation/edit-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated registreAffectation`, "success");
		});
    } catch (err) {
        alert(err);
    }
}