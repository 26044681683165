
import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'

import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'

import {whoami} from '../../../auto/js/users/UserInfo';
import { RIBBON_MENU } from '../../../auto/js/metadata/MenuType';

import { displayNewComplaintMtlbForm } from '../../../auto/js/forms/complaintMtlb/NewComplaintMtlbForm'
import { displayComplaintMtlbList } from '../../../auto/js/lists/complaintMtlb/ComplaintMtlbList'
import { displayRegionList } from '../../../auto/js/lists/metadata/RegionList';
import { displayNewRegionForm } from '../../../auto/js/forms/metadata/RegionForm';
import { displayDistrictList } from '../../../auto/js/lists/metadata/DistrictList';
import { displayNewDistrictForm } from '../../../auto/js/forms/metadata/DistrictForm';
import { displayRegistrePoursuiteList } from '../../../auto/js/lists/registrePoursuite/RegistrePoursuiteList';

import { displayRegistreAffectationList } from '../../../auto/js/lists/registreAffectation/RegistreAffectationList';

import { displaySoitTransmisList } from '../../../auto/js/lists/soitTransmis/SoitTransmisList';
import { displayCaseDataList } from '../../../auto/js/lists/caseData/CaseDataList';
import { displayNewRegistreOppositionForm } from '../../../auto/js/forms/registreOpposition/NewRegistreOppositionForm';


export const menu = () => {
    return {
		...((whoami().roles.includes('ALL') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('OPJ') || whoami().roles.includes('GREFFIER_PENAL_BO') || whoami().roles.includes('GREFFIER_ADMIN')) && {
		"ra" : {
				"ra": {
					submenu: {
						...(whoami().roles.includes('ALL') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_PENAL_BO') || whoami().roles.includes('GREFFIER_ADMIN')) && {
						"actions": {
							options: {
 								...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_PENAL_BO')) &&
								{ new: { label: "Nouveau", do: (gui) => displayNewComplaintMtlbForm(gui) }},
							},
							label: "Actions"
						}},
					},
					label: "Registre d'arrivée",
					do: (gui) => displayComplaintMtlbList(gui)
				},
			   
			label: "Registre d'arrivée",
			layout: 'col-4'
		},
		}),
		...((whoami().roles.includes('ALL') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('OPJ') || whoami().roles.includes('GREFFIER_ADMIN')) && {
		"rp": {
				"rp": {
					submenu: {
						"actions": {
							options: {
							},
							label: "Actions"
						}
					},
					label: "Registre de poursuite",
					do: (gui) => displayRegistrePoursuiteList(gui)
				}
			,
			label: "Registre de poursuite",
			layout: 'col-4'
		}
		}),
		...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN')) && {
		"raf": {
				"raf": {
					submenu: {
						"actions": {
							options: {
							},
							label: "Actions"
						}
					},
					do: (gui) => displayRegistreAffectationList(gui),
					label: "Registre d'affectation"
				},
			label: "Registre d'affectation",
			layout: 'col-4'
		}
		},
		...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('GREFFIER_PENAL_BO') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR')) && {
		"na": {
				"na": {
					submenu: {
						"actions": {
							options: {
								...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_PENAL_BO')) &&
								{ new: { label: "Nouvelle", do: (gui) => displayNewComplaintMtlbForm(gui) }},
							},
							label: "Actions"
						}
					},
					do: (gui) => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]}),
					label: "Nouvelles affaires"
				},
			label: "Nouvelles affaires",
			layout: 'col-4'
		}
		},
		...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR')) && {
		"ac": {
				"ac": {
					submenu: {
						"actions": {
							options: {
							},
							label: "Actions"
						}
					},
					do: (gui) => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CLOSED"]}),
					label: "Affaires classées"
				},
			label: "Affaires classées",
			layout: 'col-4'
		}
		},
		...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('SIEGE') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR')) && {
		"aj": {
				"aj": {
					submenu: {
						"actions": {
							options: {
							},
							label: "Actions"
						}
					},
					do: (gui) => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["JUDGED"]}),
					label: "Affaires jugées"
				},
			label: "Affaires jugées",
			layout: 'col-4'
		}
		},
		...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN') || whoami().roles.includes('SIEGE') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR')) && {
		"affaire-cours": {
				"affaire-cours": {
					submenu: {
						"actions": {
							options: {
							},
							label: "Actions"
						}
					},
					do: (gui) => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CASE_OPENED"]}),
					label: "Affaires en cours"
				},
			label: "Affaires en cours",
			layout: 'col-4'
		}
		},
/* 			...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
				"crvs-admin": {
					submenu: {

						"translations": {
							options: {
								list: {label: "Translations", do: displayTranslationsList},
								new: {label: "New", do: displayNewTranslationsForm},
							},
							label: "Translations"
						},
						"region": {
							options: {
								list: {label: "Regions", do: (gui) => displayRegionList(gui)},
								new: {label: "New", do: (gui) => displayNewRegionForm(gui)},
							},
							label: "Region"
						},
						"distric": {
							options: {
								list: {label: "Districts", do: (gui) => displayDistrictList(gui)},
								new: {label: "New", do: (gui) => displayNewDistrictForm(gui)},
							},
							label: "District"
						},
					},
					label: "Configuration"
				}
			}), */


	}
}
export const menuType = RIBBON_MENU;
