import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useConstructor } from '../utils';
import { FormControl, InputLabel } from '@mui/material';

let ddmap = ( k, v ) => ( { key: k, value: v } );

let json2SortedReactSelectArray = ( data ) => {
	const items = [];
   	Object.keys( data ).sort( function( a, b ) {
   	    if (data[a] !== null && data[b] !== null)
		    return data[a].localeCompare( data[b] )
		}).map( k => {
			items.push(ddmap( k, data[k] ))
	});
	return items
}

export function SimpleAutoCompleteInput({ textFieldProps, ...props }) {
	const [options, setOptions] = React.useState(props.options)
	const [value, setValue] = React.useState((props.defaultValue)?props.defaultValue:{})

	const updateOptions = (newOptions) => {
		setOptions(newOptions)
	}

	const clear = () => {
        if (props.handleChange)
			props.handleChange({});
		setValue({});
	}

	useConstructor(() => {
		if (props.reset)
			props.reset.subscribe(clear);

		if (props.observable)
			props.observable.subscribe(updateOptions);
	});

	const handleChange = (event, value) => {
		if (props.handleChange)
			props.handleChange(value);
		setValue(value);
	};

	return (
		<div className={props.layout}>
		<FormControl variant="standard">
			<InputLabel shrink htmlFor={props.name}>
				{props.label}
        	</InputLabel>
          <Autocomplete
		  	value={value}
            options={json2SortedReactSelectArray(options)}
            getOptionLabel={(option) => (option.value)?option.value:""}
            id={props.id}
            autoComplete
            onChange={handleChange}
            renderInput={(params) => (
            	<TextField {...params} variant="standard" disabled={props.disabled}/>
            )}
			inputProps={{ 'aria-label': 'Without label' }}
			disabled={props.disabled}
            />
		</FormControl>
		</div>

        
	);
}