export const pojoMetadata = {
			'records': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "face", name: "face", editable: true, resizable: true, type: "blob" },
			   	{ key: "fatherName", name: "father_name", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "idNumber", name: "id_number", editable: true, resizable: true, type: "text" },
			   	{ key: "idType", name: "id_type", editable: true, resizable: true, type: "number" },
			   	{ key: "lastname", name: "lastname", editable: true, resizable: true, type: "text" },
			   	{ key: "othernames", name: "othernames", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.fatherName == "")
					data.fatherName = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.idNumber == "")
					data.idNumber = null;
				if(data.lastname == "")
					data.lastname = null;
				if(data.othernames == "")
					data.othernames = null;
				return data
			}
		},
		'pv': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "plaintId", name: "plaint_id", editable: true, resizable: true, type: "number" },
			   	{ key: "pvDate", name: "pv_date", editable: true, resizable: true, type: "date" },
			],
			form2dto: (data) => {
				if(data.legacyId == "")
					data.legacyId = null;
				if(data.pvDate != null && data.pvDate instanceof Date)
					data.pvDate = data.pvDate.getFullYear() + "-" + ('0' + (data.pvDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.pvDate.getDate()).slice(-2);
				return data
			}
		},
		'plaignants': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "lastname", name: "lastname", editable: true, resizable: true, type: "text" },
			   	{ key: "othernames", name: "othernames", editable: true, resizable: true, type: "text" },
			   	{ key: "recordId", name: "record_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.firstname == "")
					data.firstname = null;
				if(data.lastname == "")
					data.lastname = null;
				if(data.othernames == "")
					data.othernames = null;
				return data
			}
		},
		'complaint-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "complainantAddress", name: "complainant_address", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantContactPreferences", name: "complainant_contact_preferences", editable: true, resizable: true, type: "number" },
			   	{ key: "complainantEmailAddress", name: "complainant_email_address", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantFirstName", name: "complainant_first_name", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantIdCardNumber", name: "complainant_id_card_number", editable: true, resizable: true, type: "number" },
			   	{ key: "complainantLastName", name: "complainant_last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantPhoneNumber", name: "complainant_phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "desiredOutcome", name: "desired_outcome", editable: true, resizable: true, type: "text" },
			   	{ key: "evidence", name: "evidence", editable: true, resizable: true, type: "text" },
			   	{ key: "incidentCategory", name: "incident_category", editable: true, resizable: true, type: "number" },
			   	{ key: "incidentDate", name: "incident_date", editable: true, resizable: true, type: "date" },
			   	{ key: "incidentTime", name: "incident_time", editable: true, resizable: true, type: "time" },
			   	{ key: "jurisdiction", name: "jurisdiction", editable: true, resizable: true, type: "text" },
			   	{ key: "subjectAddress", name: "subject_address", editable: true, resizable: true, type: "text" },
			   	{ key: "subjectOfComplaint", name: "subject_of_complaint", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.complainantAddress == "")
					data.complainantAddress = null;
				if(data.complainantEmailAddress == "")
					data.complainantEmailAddress = null;
				if(data.complainantFirstName == "")
					data.complainantFirstName = null;
				if(data.complainantLastName == "")
					data.complainantLastName = null;
				if(data.complainantPhoneNumber == "")
					data.complainantPhoneNumber = null;
				if(data.description == "")
					data.description = null;
				if(data.desiredOutcome == "")
					data.desiredOutcome = null;
				if(data.incidentDate != null && data.incidentDate instanceof Date)
					data.incidentDate = data.incidentDate.getFullYear() + "-" + ('0' + (data.incidentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.incidentDate.getDate()).slice(-2);
				if(data.jurisdiction == "")
					data.jurisdiction = null;
				if(data.subjectAddress == "")
					data.subjectAddress = null;
				if(data.subjectOfComplaint == "")
					data.subjectOfComplaint = null;
				return data
			}
		},
		'victimes': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "lastname", name: "lastname", editable: true, resizable: true, type: "text" },
			   	{ key: "othernames", name: "othernames", editable: true, resizable: true, type: "text" },
			   	{ key: "recordsId", name: "records_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.firstname == "")
					data.firstname = null;
				if(data.lastname == "")
					data.lastname = null;
				if(data.othernames == "")
					data.othernames = null;
				return data
			}
		},
		'complaint-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "complaintMtlbId", name: "complaint_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'jugement-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "date", name: "date", editable: true, resizable: true, type: "date" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "numeroCopie", name: "numero_copie", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.date != null && data.date instanceof Date)
					data.date = data.date.getFullYear() + "-" + ('0' + (data.date.getMonth() + 1)).slice(-2) + "-" + ('0' + data.date.getDate()).slice(-2);
				return data
			}
		},
		'registre-appel': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "judgmentDate", name: "judgment_date", editable: true, resizable: true, type: "date" },
			   	{ key: "judgmentNumber", name: "judgment_number", editable: true, resizable: true, type: "number" },
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "person", name: "person", editable: true, resizable: true, type: "number" },
			   	{ key: "rpId", name: "rp_id", editable: true, resizable: true, type: "number" },
			   	{ key: "transmissionDate", name: "transmission_date", editable: true, resizable: true, type: "date" },
			],
			form2dto: (data) => {
				if(data.judgmentDate != null && data.judgmentDate instanceof Date)
					data.judgmentDate = data.judgmentDate.getFullYear() + "-" + ('0' + (data.judgmentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.judgmentDate.getDate()).slice(-2);
				if(data.legacyId == "")
					data.legacyId = null;
				if(data.transmissionDate != null && data.transmissionDate instanceof Date)
					data.transmissionDate = data.transmissionDate.getFullYear() + "-" + ('0' + (data.transmissionDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.transmissionDate.getDate()).slice(-2);
				return data
			}
		},
		'e-id-mtlb-tag': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "content", name: "content", editable: true, resizable: true, type: "text" },
			   	{ key: "eIdMtlbId", name: "e_id_mtlb_id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.content == "")
					data.content = null;
				if(data.key == "")
					data.key = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'soit-transmis': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "action", name: "action", editable: true, resizable: true, type: "number" },
			   	{ key: "attribution", name: "attribution", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "avis", name: "avis", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "bp", name: "bp", editable: true, resizable: true, type: "text" },
			   	{ key: "citation", name: "citation", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "communication", name: "communication", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "destination", name: "destination", editable: true, resizable: true, type: "number" },
			   	{ key: "execution", name: "execution", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "information", name: "information", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "phoneNumber", name: "phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "place", name: "place", editable: true, resizable: true, type: "text" },
			   	{ key: "ra", name: "ra", editable: true, resizable: true, type: "number" },
			   	{ key: "retourObjetRempli", name: "retour_objet_rempli", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "sDate", name: "s_date", editable: true, resizable: true, type: "date" },
			   	{ key: "signification", name: "signification", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.bp == "")
					data.bp = null;
				if(data.phoneNumber == "")
					data.phoneNumber = null;
				if(data.place == "")
					data.place = null;
				if(data.sDate != null && data.sDate instanceof Date)
					data.sDate = data.sDate.getFullYear() + "-" + ('0' + (data.sDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.sDate.getDate()).slice(-2);
				return data
			}
		},
		'geo-data': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
			   	{ key: "disabled", name: "disabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "flag", name: "flag", editable: true, resizable: true, type: "blob" },
			   	{ key: "flagMimeType", name: "flag_mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "parentId", name: "parent_id", editable: true, resizable: true, type: "number" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.areaId == "")
					data.areaId = null;
				if(data.flagMimeType == "")
					data.flagMimeType = null;
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'substitut-de-procureur-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "birthDate", name: "birth_date", editable: true, resizable: true, type: "date" },
			   	{ key: "idCard", name: "id_card", editable: true, resizable: true, type: "text" },
			   	{ key: "lastName", name: "last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "mail", name: "mail", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "phone", name: "phone", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.birthDate != null && data.birthDate instanceof Date)
					data.birthDate = data.birthDate.getFullYear() + "-" + ('0' + (data.birthDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthDate.getDate()).slice(-2);
				if(data.idCard == "")
					data.idCard = null;
				if(data.lastName == "")
					data.lastName = null;
				if(data.mail == "")
					data.mail = null;
				if(data.name == "")
					data.name = null;
				if(data.phone == "")
					data.phone = null;
				return data
			}
		},
		'user-preferences': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'registre-affectation': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
			   	{ key: "complaintDate", name: "complaint_date", editable: true, resizable: true, type: "date" },
			   	{ key: "complaintTime", name: "complaint_time", editable: true, resizable: true, type: "time" },
			   	{ key: "complaintType", name: "complaint_type", editable: true, resizable: true, type: "number" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "initialInfraction", name: "initial_infraction", editable: true, resizable: true, type: "number" },
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "parquetRegion", name: "parquet_region", editable: true, resizable: true, type: "text" },
			   	{ key: "paymentReceiptFile", name: "payment_receipt_file", editable: true, resizable: true, type: "text" },
			   	{ key: "pvFile", name: "pv_file", editable: true, resizable: true, type: "text" },
			   	{ key: "pvId", name: "pv_id", editable: true, resizable: true, type: "text" },
			   	{ key: "rpId", name: "rp_id", editable: true, resizable: true, type: "number" },
			   	{ key: "soitTransmisFile", name: "soit_transmis_file", editable: true, resizable: true, type: "text" },
			   	{ key: "soitTransmisId", name: "soit_transmis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "substitutIdCard", name: "substitut_id_card", editable: true, resizable: true, type: "text" },
			   	{ key: "unit", name: "unit", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.complaintDate != null && data.complaintDate instanceof Date)
					data.complaintDate = data.complaintDate.getFullYear() + "-" + ('0' + (data.complaintDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.complaintDate.getDate()).slice(-2);
				if(data.legacyId == "")
					data.legacyId = null;
				if(data.parquetRegion == "")
					data.parquetRegion = null;
				if(data.pvId == "")
					data.pvId = null;
				if(data.substitutIdCard == "")
					data.substitutIdCard = null;
				return data
			}
		},
		'case-data': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "caseId", name: "case_id", editable: true, resizable: true, type: "number" },
			   	{ key: "data", name: "data", editable: true, resizable: true, type: "json" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'audience': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
			   	{ key: "convocationDesTemoins", name: "convocation_des_temoins", editable: true, resizable: true, type: "text" },
			   	{ key: "date", name: "date", editable: true, resizable: true, type: "date" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "mandatDAmener", name: "mandat_d_amener", editable: true, resizable: true, type: "text" },
			   	{ key: "mandatDArret", name: "mandat_d_arret", editable: true, resizable: true, type: "text" },
			   	{ key: "mandatDeDepot", name: "mandat_de_depot", editable: true, resizable: true, type: "text" },
			   	{ key: "time", name: "time", editable: true, resizable: true, type: "time" },
			],
			form2dto: (data) => {
				if(data.date != null && data.date instanceof Date)
					data.date = data.date.getFullYear() + "-" + ('0' + (data.date.getMonth() + 1)).slice(-2) + "-" + ('0' + data.date.getDate()).slice(-2);
				return data
			}
		},
		'address': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "areaId", name: "area_id", editable: true, resizable: true, type: "text" },
			   	{ key: "recordsId", name: "records_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.areaId == "")
					data.areaId = null;
				return data
			}
		},
		'registre-poursuite': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
			   	{ key: "caseStatus", name: "case_status", editable: true, resizable: true, type: "number" },
			   	{ key: "complaintDate", name: "complaint_date", editable: true, resizable: true, type: "date" },
			   	{ key: "complaintTime", name: "complaint_time", editable: true, resizable: true, type: "time" },
			   	{ key: "complaintType", name: "complaint_type", editable: true, resizable: true, type: "number" },
			   	{ key: "enrollmentDate", name: "enrollment_date", editable: true, resizable: true, type: "date" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "initialInfraction", name: "initial_infraction", editable: true, resizable: true, type: "number" },
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "parquetRegion", name: "parquet_region", editable: true, resizable: true, type: "text" },
			   	{ key: "paymentReceiptFile", name: "payment_receipt_file", editable: true, resizable: true, type: "text" },
			   	{ key: "pvFile", name: "pv_file", editable: true, resizable: true, type: "text" },
			   	{ key: "pvId", name: "pv_id", editable: true, resizable: true, type: "text" },
			   	{ key: "soitTransmisId", name: "soit_transmis_id", editable: true, resizable: true, type: "text" },
			   	{ key: "soitTransmitFile", name: "soit_transmit_file", editable: true, resizable: true, type: "text" },
			   	{ key: "unit", name: "unit", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.complaintDate != null && data.complaintDate instanceof Date)
					data.complaintDate = data.complaintDate.getFullYear() + "-" + ('0' + (data.complaintDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.complaintDate.getDate()).slice(-2);
				if(data.enrollmentDate != null && data.enrollmentDate instanceof Date)
					data.enrollmentDate = data.enrollmentDate.getFullYear() + "-" + ('0' + (data.enrollmentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.enrollmentDate.getDate()).slice(-2);
				if(data.legacyId == "")
					data.legacyId = null;
				if(data.parquetRegion == "")
					data.parquetRegion = null;
				if(data.pvId == "")
					data.pvId = null;
				if(data.soitTransmisId == "")
					data.soitTransmisId = null;
				return data
			}
		},
		'complaint-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantAddress", name: "complainant_address", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantContactPreferences", name: "complainant_contact_preferences", editable: true, resizable: true, type: "number" },
			   	{ key: "complainantEmailAddress", name: "complainant_email_address", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantFirstName", name: "complainant_first_name", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantIdCardNumber", name: "complainant_id_card_number", editable: true, resizable: true, type: "number" },
			   	{ key: "complainantLastName", name: "complainant_last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "complainantPhoneNumber", name: "complainant_phone_number", editable: true, resizable: true, type: "text" },
			   	{ key: "complaintRecordId", name: "complaint_record_id", editable: true, resizable: true, type: "number" },
			   	{ key: "created", name: "created", editable: true, resizable: true, type: "timestampz" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "desiredOutcome", name: "desired_outcome", editable: true, resizable: true, type: "text" },
			   	{ key: "evidence", name: "evidence", editable: true, resizable: true, type: "text" },
			   	{ key: "incidentCategory", name: "incident_category", editable: true, resizable: true, type: "number" },
			   	{ key: "incidentDate", name: "incident_date", editable: true, resizable: true, type: "date" },
			   	{ key: "incidentTime", name: "incident_time", editable: true, resizable: true, type: "time" },
			   	{ key: "jurisdiction", name: "jurisdiction", editable: true, resizable: true, type: "text" },
			   	{ key: "lastModified", name: "last_modified", editable: true, resizable: true, type: "timestampz" },
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "pvId", name: "pv_id", editable: true, resizable: true, type: "number" },
			   	{ key: "subjectAddress", name: "subject_address", editable: true, resizable: true, type: "text" },
			   	{ key: "subjectOfComplaint", name: "subject_of_complaint", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.complainantAddress == "")
					data.complainantAddress = null;
				if(data.complainantEmailAddress == "")
					data.complainantEmailAddress = null;
				if(data.complainantFirstName == "")
					data.complainantFirstName = null;
				if(data.complainantLastName == "")
					data.complainantLastName = null;
				if(data.complainantPhoneNumber == "")
					data.complainantPhoneNumber = null;
				if(data.created != null && data.created instanceof Date)
					data.created = data.created.getFullYear() + "-" + ('0' + (data.created.getMonth() + 1)).slice(-2) + "-" + ('0' + data.created.getDate()).slice(-2);
				if(data.description == "")
					data.description = null;
				if(data.desiredOutcome == "")
					data.desiredOutcome = null;
				if(data.incidentDate != null && data.incidentDate instanceof Date)
					data.incidentDate = data.incidentDate.getFullYear() + "-" + ('0' + (data.incidentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.incidentDate.getDate()).slice(-2);
				if(data.jurisdiction == "")
					data.jurisdiction = null;
				if(data.lastModified != null && data.lastModified instanceof Date)
					data.lastModified = data.lastModified.getFullYear() + "-" + ('0' + (data.lastModified.getMonth() + 1)).slice(-2) + "-" + ('0' + data.lastModified.getDate()).slice(-2);
				if(data.legacyId == "")
					data.legacyId = null;
				if(data.subjectAddress == "")
					data.subjectAddress = null;
				if(data.subjectOfComplaint == "")
					data.subjectOfComplaint = null;
				return data
			}
		},
		'mis-cause': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "lastname", name: "lastname", editable: true, resizable: true, type: "text" },
			   	{ key: "othernames", name: "othernames", editable: true, resizable: true, type: "text" },
			   	{ key: "recordsId", name: "records_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.firstname == "")
					data.firstname = null;
				if(data.lastname == "")
					data.lastname = null;
				if(data.othernames == "")
					data.othernames = null;
				return data
			}
		},
		'i18n': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "domainId", name: "domain_id", editable: true, resizable: true, type: "text" },
			   	{ key: "localeId", name: "locale_id", editable: true, resizable: true, type: "text" },
			   	{ key: "messageId", name: "message_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.domainId == "")
					data.domainId = null;
				if(data.localeId == "")
					data.localeId = null;
				if(data.messageId == "")
					data.messageId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'mandat-amener': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "audienceDate", name: "audience_date", editable: true, resizable: true, type: "date" },
			   	{ key: "audienceHour", name: "audience_hour", editable: true, resizable: true, type: "time" },
			   	{ key: "caseId", name: "case_id", editable: true, resizable: true, type: "number" },
			   	{ key: "date", name: "date", editable: true, resizable: true, type: "date" },
			   	{ key: "personne", name: "personne", editable: true, resizable: true, type: "text" },
			   	{ key: "president", name: "president", editable: true, resizable: true, type: "text" },
			   	{ key: "pv", name: "pv", editable: true, resizable: true, type: "text" },
			   	{ key: "rp", name: "rp", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.audienceDate != null && data.audienceDate instanceof Date)
					data.audienceDate = data.audienceDate.getFullYear() + "-" + ('0' + (data.audienceDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.audienceDate.getDate()).slice(-2);
				if(data.date != null && data.date instanceof Date)
					data.date = data.date.getFullYear() + "-" + ('0' + (data.date.getMonth() + 1)).slice(-2) + "-" + ('0' + data.date.getDate()).slice(-2);
				if(data.personne == "")
					data.personne = null;
				if(data.president == "")
					data.president = null;
				if(data.pv == "")
					data.pv = null;
				return data
			}
		},
		'registre-affectation-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
			   	{ key: "complaintDate", name: "complaint_date", editable: true, resizable: true, type: "date" },
			   	{ key: "complaintTime", name: "complaint_time", editable: true, resizable: true, type: "time" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "initialInfraction", name: "initial_infraction", editable: true, resizable: true, type: "number" },
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "number" },
			   	{ key: "parquetRegion", name: "parquet_region", editable: true, resizable: true, type: "text" },
			   	{ key: "paymentReceiptFile", name: "payment_receipt_file", editable: true, resizable: true, type: "text" },
			   	{ key: "pvFile", name: "pv_file", editable: true, resizable: true, type: "text" },
			   	{ key: "pvId", name: "pv_id", editable: true, resizable: true, type: "number" },
			   	{ key: "soitTransmisFile", name: "soit_transmis_file", editable: true, resizable: true, type: "text" },
			   	{ key: "soitTransmisId", name: "soit_transmis_id", editable: true, resizable: true, type: "number" },
			   	{ key: "substitutIdCard", name: "substitut_id_card", editable: true, resizable: true, type: "text" },
			   	{ key: "unit", name: "unit", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.complaintDate != null && data.complaintDate instanceof Date)
					data.complaintDate = data.complaintDate.getFullYear() + "-" + ('0' + (data.complaintDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.complaintDate.getDate()).slice(-2);
				if(data.parquetRegion == "")
					data.parquetRegion = null;
				if(data.substitutIdCard == "")
					data.substitutIdCard = null;
				if(data.unit == "")
					data.unit = null;
				return data
			}
		},
		'registre-opposition': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
			   	{ key: "comparu", name: "comparu", editable: true, resizable: true, type: "text" },
			   	{ key: "devantNous", name: "devant_nous", editable: true, resizable: true, type: "text" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "interprete", name: "interprete", editable: true, resizable: true, type: "text" },
			   	{ key: "judgmentDate", name: "judgment_date", editable: true, resizable: true, type: "date" },
			   	{ key: "judgmentNumber", name: "judgment_number", editable: true, resizable: true, type: "number" },
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "oppositionDate", name: "opposition_date", editable: true, resizable: true, type: "date" },
			   	{ key: "partie", name: "partie", editable: true, resizable: true, type: "text" },
			   	{ key: "person", name: "person", editable: true, resizable: true, type: "number" },
			   	{ key: "qui", name: "qui", editable: true, resizable: true, type: "text" },
			   	{ key: "rpId", name: "rp_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.comparu == "")
					data.comparu = null;
				if(data.devantNous == "")
					data.devantNous = null;
				if(data.interprete == "")
					data.interprete = null;
				if(data.judgmentDate != null && data.judgmentDate instanceof Date)
					data.judgmentDate = data.judgmentDate.getFullYear() + "-" + ('0' + (data.judgmentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.judgmentDate.getDate()).slice(-2);
				if(data.legacyId == "")
					data.legacyId = null;
				if(data.oppositionDate != null && data.oppositionDate instanceof Date)
					data.oppositionDate = data.oppositionDate.getFullYear() + "-" + ('0' + (data.oppositionDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.oppositionDate.getDate()).slice(-2);
				if(data.partie == "")
					data.partie = null;
				if(data.qui == "")
					data.qui = null;
				return data
			}
		},
		'folder': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "folderStatus", name: "folder_status", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'audience-record': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "attachments", name: "attachments", editable: true, resizable: true, type: "text" },
			   	{ key: "convocationDesTemoins", name: "convocation_des_temoins", editable: true, resizable: true, type: "text" },
			   	{ key: "date", name: "date", editable: true, resizable: true, type: "date" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "mandatDAmener", name: "mandat_d_amener", editable: true, resizable: true, type: "text" },
			   	{ key: "mandatDArret", name: "mandat_d_arret", editable: true, resizable: true, type: "text" },
			   	{ key: "mandatDeDepot", name: "mandat_de_depot", editable: true, resizable: true, type: "text" },
			   	{ key: "time", name: "time", editable: true, resizable: true, type: "time" },
			],
			form2dto: (data) => {
				if(data.date != null && data.date instanceof Date)
					data.date = data.date.getFullYear() + "-" + ('0' + (data.date.getMonth() + 1)).slice(-2) + "-" + ('0' + data.date.getDate()).slice(-2);
				return data
			}
		},
		'mandat-depot': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "address", name: "address", editable: true, resizable: true, type: "text" },
			   	{ key: "articles", name: "articles", editable: true, resizable: true, type: "text" },
			   	{ key: "birthPlace", name: "birth_place", editable: true, resizable: true, type: "text" },
			   	{ key: "birthdate", name: "birthdate", editable: true, resizable: true, type: "date" },
			   	{ key: "caseId", name: "case_id", editable: true, resizable: true, type: "number" },
			   	{ key: "chargedWith", name: "charged_with", editable: true, resizable: true, type: "text" },
			   	{ key: "citationDirect", name: "citation_direct", editable: true, resizable: true, type: "date" },
			   	{ key: "citizenship", name: "citizenship", editable: true, resizable: true, type: "text" },
			   	{ key: "codePenal", name: "code_penal", editable: true, resizable: true, type: "text" },
			   	{ key: "firstname", name: "firstname", editable: true, resizable: true, type: "text" },
			   	{ key: "flagrantDelits", name: "flagrant_delits", editable: true, resizable: true, type: "date" },
			   	{ key: "lastname", name: "lastname", editable: true, resizable: true, type: "text" },
			   	{ key: "profession", name: "profession", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.address == "")
					data.address = null;
				if(data.articles == "")
					data.articles = null;
				if(data.birthPlace == "")
					data.birthPlace = null;
				if(data.birthdate != null && data.birthdate instanceof Date)
					data.birthdate = data.birthdate.getFullYear() + "-" + ('0' + (data.birthdate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthdate.getDate()).slice(-2);
				if(data.chargedWith == "")
					data.chargedWith = null;
				if(data.citationDirect != null && data.citationDirect instanceof Date)
					data.citationDirect = data.citationDirect.getFullYear() + "-" + ('0' + (data.citationDirect.getMonth() + 1)).slice(-2) + "-" + ('0' + data.citationDirect.getDate()).slice(-2);
				if(data.citizenship == "")
					data.citizenship = null;
				if(data.codePenal == "")
					data.codePenal = null;
				if(data.firstname == "")
					data.firstname = null;
				if(data.flagrantDelits != null && data.flagrantDelits instanceof Date)
					data.flagrantDelits = data.flagrantDelits.getFullYear() + "-" + ('0' + (data.flagrantDelits.getMonth() + 1)).slice(-2) + "-" + ('0' + data.flagrantDelits.getDate()).slice(-2);
				if(data.lastname == "")
					data.lastname = null;
				if(data.profession == "")
					data.profession = null;
				return data
			}
		},
		'issued-document': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "cancelledDate", name: "cancelled_date", editable: true, resizable: true, type: "date" },
			   	{ key: "cancelledReason", name: "cancelled_reason", editable: true, resizable: true, type: "text" },
			   	{ key: "documentId", name: "document_id", editable: true, resizable: true, type: "text" },
			   	{ key: "expires", name: "expires", editable: true, resizable: true, type: "date" },
			   	{ key: "fullName", name: "full_name", editable: true, resizable: true, type: "text" },
			   	{ key: "idCardNumber", name: "id_card_number", editable: true, resizable: true, type: "number" },
			   	{ key: "issuedDate", name: "issued_date", editable: true, resizable: true, type: "date" },
			   	{ key: "notes", name: "notes", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.cancelledDate != null && data.cancelledDate instanceof Date)
					data.cancelledDate = data.cancelledDate.getFullYear() + "-" + ('0' + (data.cancelledDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.cancelledDate.getDate()).slice(-2);
				if(data.cancelledReason == "")
					data.cancelledReason = null;
				if(data.documentId == "")
					data.documentId = null;
				if(data.expires != null && data.expires instanceof Date)
					data.expires = data.expires.getFullYear() + "-" + ('0' + (data.expires.getMonth() + 1)).slice(-2) + "-" + ('0' + data.expires.getDate()).slice(-2);
				if(data.fullName == "")
					data.fullName = null;
				if(data.issuedDate != null && data.issuedDate instanceof Date)
					data.issuedDate = data.issuedDate.getFullYear() + "-" + ('0' + (data.issuedDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.issuedDate.getDate()).slice(-2);
				if(data.notes == "")
					data.notes = null;
				return data
			}
		},
		'e-id-mtlb': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "documentId", name: "document_id", editable: true, resizable: true, type: "text" },
			   	{ key: "firstName", name: "first_name", editable: true, resizable: true, type: "text" },
			   	{ key: "idCardNumber", name: "id_card_number", editable: true, resizable: true, type: "number" },
			   	{ key: "lastName", name: "last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "mtlbStatus", name: "mtlb_status", editable: true, resizable: true, type: "number" },
			   	{ key: "mtlbType", name: "mtlb_type", editable: true, resizable: true, type: "number" },
			   	{ key: "password", name: "password", editable: true, resizable: true, type: "password" },
			],
			form2dto: (data) => {
				if(data.documentId == "")
					data.documentId = null;
				if(data.firstName == "")
					data.firstName = null;
				if(data.lastName == "")
					data.lastName = null;
				if(data.password == "")
					data.password = null;
				return data
			}
		},
		'case-tags': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "caseId", name: "case_id", editable: true, resizable: true, type: "number" },
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'substitut-de-procureur': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "birthDate", name: "birth_date", editable: true, resizable: true, type: "date" },
			   	{ key: "idCard", name: "id_card", editable: true, resizable: true, type: "text" },
			   	{ key: "lastName", name: "last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "mail", name: "mail", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "phone", name: "phone", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.birthDate != null && data.birthDate instanceof Date)
					data.birthDate = data.birthDate.getFullYear() + "-" + ('0' + (data.birthDate.getMonth() + 1)).slice(-2) + "-" + ('0' + data.birthDate.getDate()).slice(-2);
				if(data.idCard == "")
					data.idCard = null;
				if(data.lastName == "")
					data.lastName = null;
				if(data.mail == "")
					data.mail = null;
				if(data.name == "")
					data.name = null;
				if(data.phone == "")
					data.phone = null;
				return data
			}
		},
		'jugement': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "copieJugementFile", name: "copie_jugement_file", editable: true, resizable: true, type: "text" },
			   	{ key: "date", name: "date", editable: true, resizable: true, type: "date" },
			   	{ key: "folderId", name: "folder_id", editable: true, resizable: true, type: "number" },
			   	{ key: "legacyId", name: "legacy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "numeroCopie", name: "numero_copie", editable: true, resizable: true, type: "number" },
			   	{ key: "rpId", name: "rp_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.date != null && data.date instanceof Date)
					data.date = data.date.getFullYear() + "-" + ('0' + (data.date.getMonth() + 1)).slice(-2) + "-" + ('0' + data.date.getDate()).slice(-2);
				if(data.legacyId == "")
					data.legacyId = null;
				return data
			}
		},
}

export const buildEmptyObject = (entity) => {
	var columns = new Object();
	let fields = pojoMetadata[entity].columns
	for (let col in fields) {		
		if(fields[col].type == 'text'){
			columns[fields[col].key] = "";
		}else if(fields[col].type=='checkbox'){
			columns[fields[col].key] = false;
		}else if(fields[col].type=='datetime-local'){
			columns[fields[col].key] = "";
		}
		else if(fields[col].type=='autocomplete'){
			columns[fields[col].key] = null;
		}
	 }
	return columns
};

export const getEntityIdCol = (entity) => {
	return pojoMetadata[entity].columns[0].key;
}

export const getRowId = (entity, row) => {
	return row[getEntityIdCol(entity)];
}

