import React from "react";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";
import TemplateComponent from "react-mustache-template-component";
import { PRINT_EVENT } from "../../../../auto/js/events/Gui";
import { rest, t } from "../../../../auto/js/services";
import { geoDataMetadataLoader } from "../../../../auto/js/metadata/GeoDataMetadataLoader";
import { save, update } from "../../../../auto/js/forms/soitTransmis/NewSoitTransmisForm";

const unit = {
	1: "POL",
	2: "GEN",
	3: "GAC",
}

export const customSoitTransmisForm2Dto = (formData, dto) => {
}

export const idDisplay = () => {
    return false;
}

export const customSoitTransmisDto2Form = (dto, form) => {

}

export const placeComponentHook = (name, disabled) => {
    return <GeoDataComponent name={name} disabled={disabled} linear={true} default={"48"}/>
}

export const placeDisplay = () => {
    return true;
}

export const printSoitTransmis = async (data) => {
    return update(data).then(async () => {
        return rest.read('forms/soit-transmis', data.id).then(async soitTransmis => {
            printTemplate(soitTransmis)
        })
    })
}

export const print = (id) => {
    rest.read('forms/soit-transmis', id).then(async soitTransmis => {
        printTemplate(soitTransmis)
    })
}

const printTemplate = (data) => {
	PRINT_EVENT.publish({
		content: <TemplateComponent template={data.template} data={dto2form(data.templateData)} />,
		copyParentStryle: false
	})
}

const dto2form = (dto) => {
	let form = dto; 
	if (dto.place != null)
		form.place = formatLocation(dto.place);
	if (dto.destination != null)
		form.destination = t(unit[dto.destination]);
	return form;
}

const formatLocation = (location) => {
	let destinationPlace = [];
	if (location != null) {
		let destinationPlaceComponents = location.split(".");
		let place = location;
		destinationPlace.push(
			(geoDataMetadataLoader.getArea(place))?" " + t(geoDataMetadataLoader.getArea(place).name):t("INVALID DATA ")
		)
		for (let i = 0; i < destinationPlaceComponents.length - 1 ; i ++) {
			let parentAreaId = place.substring(0, place.lastIndexOf("."));
			place = parentAreaId;
			destinationPlace.push(
				(geoDataMetadataLoader.getArea(parentAreaId))?" " + t(geoDataMetadataLoader.getArea(parentAreaId).name):t("INVALID DATA ")
			)                
		}
	}
	return destinationPlace;
}