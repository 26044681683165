import { Observable } from "../../auto/js/events/Observable";

class Memstore {
    constructor() {
        if (!!Memstore.instance) {
            return Memstore.instance;
        }

        Memstore.instance = this;
        this.seq = 0;
        return this;
    }

    save(data) {
        return new SharedObjectWrapper(data, this.seq + 1)
    }
   
}
export const memstore = new Memstore();

class SharedObjectWrapper {

    constructor(data, storeId) {
        this.data = data;
        this.storeId = storeId;
        this.observable = new Observable();
    }

    persist(data) {
        this.data = data;
        this.observable.publish(this.data);
    }

}