export const IncidentCategory = {
	"CONSUMER_RIGHTS": 1
	,
	"ENVIRONMENTAL_CONCERNS": 2
	,
	"PUBLIC_SAFETY": 3
	,
	"GOVERNMENTAL_CONCERN": 4
	
}
Object.freeze(IncidentCategory);
const toTypeMap = {};
Object.keys(IncidentCategory).forEach(key => {
	toTypeMap[IncidentCategory[key]] = key;
});
export function getIncidentCategory (value) {
	return toTypeMap[value];
}

export function getAllIncidentCategory () {
    return Object.keys(IncidentCategory);
}

export const INCIDENT_CATEGORY_CONSUMER_RIGHTS = "CONSUMER_RIGHTS";
export const INCIDENT_CATEGORY_ENVIRONMENTAL_CONCERNS = "ENVIRONMENTAL_CONCERNS";
export const INCIDENT_CATEGORY_PUBLIC_SAFETY = "PUBLIC_SAFETY";
export const INCIDENT_CATEGORY_GOVERNMENTAL_CONCERN = "GOVERNMENTAL_CONCERN";
