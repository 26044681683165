import React from "react"
import { t } from "../../../../auto/js/services"
import { loadSubstitutList } from "../../utils"
import AutocompleteOneSelection from "../../widgets/AutoCompleteOneSelection"

export const customRegistreAffectationForm2Dto = (form, dto) => {}
export const customRegistreAffectationDto2Form = (dto, form) => {
    
}

export const substitutIdCardComponentHook = (name, disabled) => {
    return <AutocompleteOneSelection loadOptions={async () => loadSubstitutList()} name={name} readOnly={disabled} label={t("Substitut ou Procureur")} />
}

export const folderIdDisplay = () => {
    return false;
}

export const idDisplay = () => {
    return false;
}