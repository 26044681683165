import React, { Component } from "react";
import { Chip, TextField, Autocomplete, Button, FormControl, InputLabel, Paper, createFilterOptions } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RemoveCircleOutlineSharp from '@mui/icons-material/RemoveCircleOutlineSharp';
import { Field, withFormik } from "formik";
import { t } from "../../../auto/js/services";
import RateLimitingQueryService from "./RateLimitingQueryService";


/**
 * Transforms the input as described in the API to the form required by the
 * GUI components.
 * 
 * @param allOptions The options object as provided to the Widget.
 * @param list The list of IDs of the options displayed as chips.
 * @returns An array of objects in the form {id: number, label: str}
 *    that stores the options that are referenced in the list.
 */
function preprocessInput(allOptions, list) {
    let options = [];

    if (list != null)
        list = list.map((elem) => String(elem));
    else
        list = [];
    for (const [key, value] of Object.entries(allOptions)) {
      if (!list.includes(key)) {
        options.push({
            "id": parseInt(key),
            "label": value,
            color: 'none'
        });
      }
    }

    return options;
}

class AutocompleteDynamicListSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            inputValue: undefined,
            value: props.field.value,
            open: false,
            selectedValue: null
        };
        this.name = props.field.name;
        this.prevInputValue = undefined;
        this.intervalId = null;
        this.setOptions = this.setOptions.bind(this);
        this.rateLimitingQueryService = new RateLimitingQueryService(this.setOptions, props.searchRequest, props.time);
        this.rateLimitingQueryService.startTimer();
        this.autocompleteRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.inputValue !== this.prevInputValue) {
            this.prevInputValue = this.state.inputValue;
            this.handleInputChange(this.state.inputValue);
        }
    }

    setOptions(value) {
        this.setState({ options: value });
    }

    handleInputChange = (filter) => {
        if (filter === "") {
            this.setState({ options: [] });
        } else if (this.props.searchRequest) {
            this.rateLimitingQueryService.setQuery(filter);
        }
        this.setState({inputValue: filter});
    }

    handleChange = (event, newValue, reason) => {
        if (newValue.id) {
            let v = { ...this.state.value, [newValue.id]: newValue.label };
            this.setState({value: v});
            this.props.form.setFieldValue(this.name, v);
            this.setState({selectedValue: {label: ""}, inputValue: ""})
        } else if (this.props.createEntry) {
 //           newValue.label = newValue.inputValue;
            this.props.createEntry(this.state.inputValue, this.addEntry, this.onCancel);
            this.setState({selectedValue: {label: newValue.inputValue}})
        }
    }

    addEntry = (entry) => {
        if (entry != null) {
            let v = { ...this.state.value, [entry.id]: entry.label };
            this.setState({value: v, open: false, options: {}, selectedValue: {label: ""}, inputValue: ""});
            this.props.form.setFieldValue(this.name, v);
        }
    }

    onCancel = () => {
        this.setState({open: true})
    }

    handleOpen = () => {
        this.setState({open: true})
    }

    handleClose = () => {
        this.setState({open: false})
    }

    render() {
        const { options } = this.state;

        const filter = createFilterOptions({matchFrom: 'any', trim: true});

        return (
            <div>      
                <FormControl variant="standard">
                <InputLabel shrink htmlFor={this.props.name}>
                    {this.props.label}
                </InputLabel>
                <Autocomplete
                    ref={this.autocompleteRef}
                    open={this.state.open}
                    onOpen={this.handleOpen}
                    onClose={this.handleClose}
                    value={this.state.selectedValue}
                    className={this.props.layout}
                    id="tags-standard"
                    readOnly={this.props.readOnly}
                    filterSelectedOptions
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== '') {
                            filtered.push({
                                inputValue: params.inputValue,
                                label: t`create or search entry: ` + params.inputValue,
                                color: '#2196f3'
                            });
                        }

                        return filtered;
                    }}
                    renderOption={(props, option) => {
                        const { label, color } = option;
                        return (
                            <span {...props} style={{ color: color }}>
                                {label}
                            </span>
                        );
                    }}
                    freeSolo
                    options={preprocessInput(options, (this.state.value)?Object.keys(this.state.value):null)}
                    getOptionLabel={option => option.label}
                    onChange={this.handleChange}
                
                    onInputChange={(event, newInputValue, reason) => {
                        /* this.setState({ inputValue: newInputValue }); */
                        this.handleInputChange(newInputValue)
                    }}
                    inputValue={this.state.inputValue}
                    aria-label={this.props.label}
                    renderTags={() => {}}
                    renderInput={params => (
                        <TextField
                            {...params}
                            value={this.state.inputValue}
                            variant="standard"
                            placeholder={this.props.label}
                            margin="normal"
                            fullWidth
 //                           label={this.props.label}
                            InputLabelProps={{ shrink: false }}
                        />
                    )}
                />
                </FormControl>
                <div className="selected-tags">
                    {(this.state.value) &&
                        Object.keys(this.state.value).map((obj, index) => (
                            <React.Fragment key={index}>
                                <Button
                                    style={{ textDecoration: 'underline', textTransform: 'unset' }}
                                    label={this.state.value[obj]}
                                    onClick={() => this.props.readEntry(obj)}
                                >
                                    {this.state.value[obj]}
                                </Button>
                                <RemoveCircleOutlineSharp
                                    color="primary"
                                    onClick={() => {
                                        const updatedChips = Object.keys(this.state.value).filter(entry => entry !== obj).reduce((newObj, key) => {
                                            newObj[key] = this.state.value[key];
                                            return newObj;
                                        }, {});
                                        this.setState({value: updatedChips});
                                        this.props.form.setFieldValue(this.name, updatedChips);
                                    }}
                                />
                            </React.Fragment>
                        ))
                    }
                </div>
                
            </div>
        );
    }
}

export default AutocompleteDynamicListSelector;
