import React, { createRef } from "react";
import { IconButton } from '@mui/material';
import {v4 as uuidv4} from 'uuid';
import { displayReadPendingComplaintMtlbForm } from "../../../auto/js/forms/complaintMtlb/PendingComplaintMtlbForm";
import { displayComplaintMtlbList } from "../../../auto/js/lists/complaintMtlb/ComplaintMtlbList";
import { displayReadApprovedComplaintMtlbForm } from "../../../auto/js/forms/complaintMtlb/ApprovedComplaintMtlbForm";
import { MTLB_STATUS_ARCHIVED, MTLB_STATUS_CLOSED, MTLB_STATUS_INVESTIGATING, MTLB_STATUS_SUBMITTED } from "../../../auto/js/metadata/MtlbStatus";
import EditIcon from '@mui/icons-material/Edit';
import { rest, t } from "../../../auto/js/services";
import { createFormComponent } from "../../../auto/js/widgets";
import { whoami } from "../../../auto/js/users/UserInfo";
import { CLOSE_SIDE_PANEL, CLOSE_VIEW_EVENT, OPEN_SIDE_PANEL } from "../../../auto/js/events/Gui";
import { updateComplaintMtlbForm } from "../../../auto/js/forms/complaintMtlb/ComplaintMtlbApi";
import { memstore } from "../Memstore";
import { IncidentCategory } from "../../../auto/js/metadata/IncidentCategory";
import { swapObject } from "../../../auto/js/utils";
import { createSidePanel, SidePanel, SidePanelView } from "../router/SidePanel";
import { complaintMtlbFields, dto2form } from "../../../auto/js/forms/complaintMtlb/NewComplaintMtlbForm";

/* export const RegistreArriveeSummary = ({...props}) => {
    return(
      <RegistreArrivee obj={props.obj} columns={['subjectOfComplaint', 'incidentCategory', 'incidentDate', 'incidentTime']} footerElements= {['created', 'lastModified']} title={"Entrée dans le registre d'arrivée"} gui={props.gui}/>
      )
} */

export class RegistreArriveeSummary extends React.Component {
    constructor(props) {
      super(props);
      this.uuid = uuidv4()
      this.state = {
        data: props.obj.data,
      }
      this.updateData = this.updateData.bind(this);
      props.obj.observable.subscribe(this.updateData)
    }

    transformData = (data) => {
        let transformed = _.clone(data);
        if(transformed.incidentDate != null)
            transformed.incidentDate =  data.incidentDate[2] + '/' + data.incidentDate[1] + '/' + data.incidentDate[0];
        if(transformed.incidentTime) {
            let h = data.incidentTime[0];
            let m = data.incidentTime[1];
            transformed.incidentTime = h + ":" + m;
        }
        if (transformed.incidentCategory != null)
            transformed.incidentCategory = t(transformed.incidentCategory);
        return transformed
    }

    buildBody = () => {
        let items = [];
        let colClass = "col-" + Math.round(12/this.props.columns.length)
        this.props.columns.forEach(element => {
            if(this.state.data[element])
                items.push(
                    <div class={colClass}>
                        <div class="results">
                            <h4>{t(element)}</h4>
                            {this.transformData(this.state.data)[element]}
                        </div>
                    </div>
                )
        });
        return items;
    }

    updateData = (newData) => {
        this.setState({data: newData})

    }

    buildFooter = () => {
        let items = [];
        this.props.footerElements.forEach(element => {
            <p style={{fontStyle: "italic", color: "#898c8e"}}>{t(element)}: {this.state.data[element]}</p>
        });
        return items;
    }

    loadFormData = async () => {
        let form = dto2form(this.state.data);
        let personFilter = {and: true};
		personFilter.victimes = {folderId: this.state.data.id};
		return rest.search('victimes', personFilter).then(victimes => {
			let v = {};
			if (victimes.length)
				victimes.forEach(element => {
                    let lastname = (element.lastname === null)?'':element.lastname;
                    let othernames = (element.othernames === null)?'':element.othernames + ' ';
                    let firstname = (element.firstname === null)?'':element.firstname + ' ';
					 v[element.id] =  firstname  + othernames  + lastname 
				});
			form.victimes = v;
            let mFilter = {and: true};
            mFilter['mis-cause'] = {folderId: this.state.data.id};
			return rest.search('mis-cause', mFilter).then(causes => {
				let m = {};
				if (causes.length)
					causes.forEach(element => {
						let lastname = (element.lastname === null)?'':element.lastname;
                        let othernames = (element.othernames === null)?'':element.othernames + ' ';
                        let firstname = (element.firstname === null)?'':element.firstname + ' ';
                        m[element.id] =  firstname  + othernames  + lastname  
                    });
				form['misCauses'] = m;
                let pFilter = {and: true};
                pFilter['plaignants'] = {folderId: this.state.data.id};
                return rest.search('plaignants', pFilter).then(plaignants => {
                    let p = {};
                    if (plaignants.length)
                        plaignants.forEach(element => {
                            let lastname = (element.lastname === null)?'':element.lastname;
                            let othernames = (element.othernames === null)?'':element.othernames + ' ';
                            let firstname = (element.firstname === null)?'':element.firstname + ' ';
                            p[element.id] = firstname  + othernames  + lastname 
                        });
                    form.plaignants = p;
				    return form
                })
			})
		})
    }

    form2persist = (data) => {
        if (data.incidentTime != null && typeof(data.incidentTime) != 'string') {
            const date = new Date(data.incidentTime)
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            data.incidentTime = [hour, minute]
        }
        if(data.incidentDate != null && data.incidentDate instanceof Date)
			data.incidentDate = [parseInt(data.incidentDate.getFullYear()), + parseInt(('0' + (data.incidentDate.getMonth() + 1)).slice(-2)), parseInt(('0' + data.incidentDate.getDate()).slice(-2))];
        return data;
    }

    openSidePanel = (id, readOnly) => {
        let Form = createFormComponent(complaintMtlbFields);
        this.props.gui.openSidePanel(<Form loadData={async () => this.loadFormData(id)} readOnly={readOnly} id={id} buttons={(whoami().roles.includes('GREFFIER_ADMIN') || readOnly)?()=>null:(data) => this.getButtons(this.props.id, () => {
            this.props.obj.persist(this.form2persist(data));
            this.props.gui.closeSidePanel();
            }, data)} />, this.props.title);
    }

    getButtons = (id, onFinish, data) => {
        return (
        <>
        <div className="row">
            <div className="col-md-3">
                <button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={(onFinish)?() => updateComplaintMtlbForm(data).then(() => onFinish()):updateComplaintMtlbForm}> {t`Update`} </button>
            </div> 
        </div>
        </>
        )
    }

    openEdit = () => {
        switch(this.state.data.mtlbStatus) {
            case (MTLB_STATUS_INVESTIGATING):
                this.openSidePanel(this.state.data.id, true)
               break;
           case (MTLB_STATUS_SUBMITTED):
                this.openSidePanel(this.state.data.id)
               break;
           case (MTLB_STATUS_CLOSED):
            this.openSidePanel(this.state.data.id, true)
                break;
           case (MTLB_STATUS_ARCHIVED):
            this.openSidePanel(this.state.data.id, true)           
            break;
       }
    }
    
    render() {
        console.log(this.uuid);
        return (
            <>

            {(this.state.data)?
            <div className="rv5info" style={{margin: 'auto', marginBottom: '15px'}}>
            <div class="row">
            <div class="results col-md-11" style={{paddingLeft:"2rem"}}><h2>{t(this.props.title)}</h2></div>
            {(!this.props.readOnly) && <div class="col-md-1">
            <IconButton aria-label={t`Edit`} onClick={() => this.openEdit()}>
                <EditIcon />
            </IconButton>
            </div>}
            </div>   
            <div class="row" style={{paddingLeft:"1rem", marginLeft: "0px"}}>
            {this.buildBody()}
            <div class="results" style={{marginRight: "10px", marginLeft: "auto"}}>
                {this.buildFooter()}
            </div>
            </div>
            
            </div>:
            <div className="ValidationError">{t(this.props.title) + ' ' + t`not found`}</div>
            }
            </>
        )    
    }
}