import React from "react";
import { IconButton } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { t } from "../../../auto/js/services";
import { createFormComponent } from "../../../auto/js/widgets";
import { dto2form, soitTransmisFields } from "../../../auto/js/forms/soitTransmis/NewSoitTransmisForm";
import { print } from "../forms/soitTransmis/SoitTransmisFormCommon";
import { swapObject } from "../../../auto/js/utils";

export const SoitTransmisSummary = ({...props}) => {
    return(
      <SoitTransmis obj={props.obj} columns={['id', 'destination']} footerElements= {['created', 'lastModified']} title={"Soit Transmis"} gui={props.gui}/>
      )
}

const destination = {
    "POL": 1,
    "GEN": 2,
    "GAC": 3
}

class SoitTransmis extends React.Component {
    constructor(props) {
      super(props);
      this.formRef = React.createRef()
      this.state = {
        data: props.obj.data,
        loading: true,
        loadingMessage: ''
      }
      this.updateData = this.updateData.bind(this);
      props.obj.observable.subscribe(this.updateData)
    }

    transformData = (data) => {
        let transformed = _.clone(data);
        if (transformed.destination != null)
            transformed.destination = t(swapObject(destination)[transformed.destination]);
        return transformed
    }

    buildBody = () => {
        let items = [];
        let colClass = "col-" + Math.round(12/this.props.columns.length)
        this.props.columns.forEach(element => {
            items.push(
                <div class={colClass}>
                    <div class="results">
                        <h4>{t(element)}</h4>
                        {this.transformData(this.state.data)[element]}
                    </div>
                </div>
            )
        });
        return items;
    }

    updateData = (data) => {
        this.setState({data: data})
    }

    buildFooter = () => {
        let items = [];
        this.props.footerElements.forEach(element => {
            <p style={{fontStyle: "italic", color: "#898c8e"}}>{t(element)}: {this.state.data[element]}</p>
        });
        return items;
    }

    loadFormData = async () => {
        let form = dto2form(this.state.data);
        return form;
    }

    form2persist = (data) => {
        if(data.sDate !== null )
			data.sDate = null
        return data;
    }

    openSidePanel = (id, readOnly) => {
        let Form = createFormComponent(soitTransmisFields);
        this.props.gui.openSidePanel(<Form loadData={async () => this.loadFormData(id)} readOnly={readOnly} id={id} buttons={() => this.getButtons(id)} />, this.props.title);
    }

    openEdit = () => {
    this.openSidePanel(this.state.data.id, true)
    }

    getButtons = (id) => {
        return (
        <>
        <div className="row">
            <div className="col-md-6">
                <button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={() => print(id)}> {t`Imprimer`} </button>
            </div>
        </div>
        </>
        )
    }
    
    render() {
        return (
            <>

            {(this.state.data)?
            <div className="rv5info" style={{margin: 'auto', marginBottom: '15px'}}>
            <div class="row">
            <div class="results col-md-11" style={{paddingLeft:"2rem"}}><h2>{t(this.props.title)}</h2></div>
            {(!this.props.readOnly) && <div class="col-md-1">
            <IconButton aria-label={t`Edit`} onClick={() => this.openEdit()}>
                <EditIcon />
            </IconButton>
            </div>}
            </div>   
            <div class="row" style={{paddingLeft:"1rem", marginLeft: "0px"}}>
            {this.buildBody()}
            <div class="results" style={{marginRight: "10px", marginLeft: "auto"}}>
                {this.buildFooter()}
            </div>
            </div>
            </div>:
            <div className="ValidationError">{t(this.props.title) + ' ' + t`not found`}</div>
            }
            </>
        )    
    }
}